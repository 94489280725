import { Types } from './types';

const initState = {
  userProfileFindProcess: false,
  userProfileFindError: null,
  userProfileFindData: null,

  userProfileUpdateProcess: false,
  userProfileUpdateError: null,
  userProfileUpdateData: null,

  userPasswordUpdateProcess: false,
  userPasswordUpdateError: null,
  userPasswordUpdateData: null,
};

export const userProfile = (state = initState, action) => {
  switch (action.type) {
    case Types.START_USER_PROFILE_FIND:
      return {
        ...state,
        userProfileFindProcess: false,
        userProfileFindError: null,
        userProfileFindData: null,
      };
    case Types.END_USER_PROFILE_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        userProfileFindProcess: false,
        userProfileFindError: error,
        userProfileFindData: success,
      };
    }

    // update user profile
    case Types.START_UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfileUpdateProcess: false,
        userProfileUpdateError: null,
        userProfileUpdateData: null,
      };
    case Types.END_UPDATE_USER_PROFILE: {
      const { success, error } = action.payload;
      return {
        ...state,
        userProfileUpdateProcess: false,
        userProfileUpdateError: error,
        userProfileUpdateData: success,
      };
    }

    // update user password
    case Types.START_UPDATE_USER_PASSWORD:
      return {
        ...state,
        userPasswordUpdateProcess: false,
        userPasswordUpdateError: null,
        userPasswordUpdateData: null,
      };
    case Types.END_UPDATE_USER_PASSWORD: {
      const { success, error } = action.payload;
      return {
        ...state,
        userPasswordUpdateProcess: false,
        userPasswordUpdateError: error,
        userPasswordUpdateData: success,
      };
    }

    default:
      return state;
  }
};
