import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Clients, CreateClient, ViewClient } from '.';
import { commonFn } from '../../../util/commonFn';
import { NotFound } from '../../common';

export const ClientsRouter = (props) => {
  return (
    <>
      <Switch>
        {commonFn.menuHiddenKeys.indexOf('list-clients') === -1 && (
          <Route exact path={'/hrms/clients'} component={Clients} />
        )}
        {commonFn.menuHiddenKeys.indexOf('create-client') === -1 && (
          <Route exact path={'/hrms/clients/create'} component={CreateClient} />
        )}
        {commonFn.menuHiddenKeys.indexOf('list-clients') === -1 && (
          <Route
            path={'/hrms/clients/view/:id'}
            render={(props) => <ViewClient isView={true} {...props} />}
          />
        )}
        {commonFn.menuHiddenKeys.indexOf('edit-client') === -1 && (
          <Route
            path={'/hrms/clients/edit/:id'}
            render={(props) => <CreateClient isEdit={true} {...props} />}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
