import React, { useEffect, useState } from 'react';
import { TableCustom, FilterCustom, TabCustom } from '../common';
import { Box, CircularProgress } from '@material-ui/core';
import { PositionHead, MainFilter, Tabs } from '../../data/PositionsData';
import { expType } from '../../data/CreatePositionData';
import './Positions.scss';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { commonFn } from '../../util/commonFn';
import { positionsFindAll } from '../../redux/positions/action';
import { useDispatch, useSelector } from 'react-redux';
import { positionDelete } from '../../redux/positions/action';
import { positionTypesFindAll } from '../../redux/master/action';
import { skillsFindAll } from '../../redux/skills/action';
import { clientsFindAll } from '../../redux/clients/action';
import { openSnackBarLayout } from '../../redux/common/action';

// Start: Main Function
export const Positions = ({ match }) => {
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const {
    positionsFindAllError,
    positionsFindAllData,
    positionsFindAllProcess,
    positionDeleteProcess,
    positionDeleteData,
    positionTypesFindAllData,
    skillsFindAllData,
    clientsFindAllData,
    userInfo,
    positionsFilterError,
    positionsFilterData,
  } = useSelector(({ positions, positionTypes, skills, clients, sign, positionsFilter }) => ({
    ...positions,
    ...positionTypes,
    ...skills,
    ...clients,
    ...sign,
    ...positionsFilter,
  }));

  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const filterAppliedData = {};
  const [filterValues, setFilterValues] = useState([]);
  const [hiddenAction, setHiddenAction] = useState([]);
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [filterHiddenKeys, setFilterHiddenKeys] = useState([]);
  const [toolTipKey, setToolTipKey] = useState([]);

  useEffect(() => {
    document.title = 'Quark - Positions';
    dispatch(positionTypesFindAll());
    dispatch(skillsFindAll());
    dispatch(clientsFindAll());
    dispatch(positionsFindAll(skip, limit));
    if (userInfo?.data?.type_slug === 'client') {
      setHiddenKeys(() => ['company_logo']);
      setFilterHiddenKeys(() => ['clients']);
    }
  }, []);

  const position_type =
    positionTypesFindAllData &&
    positionTypesFindAllData?.data?.list?.map((type) => {
      return { label: type?.position_type, value: type?.id };
    });

  const skills =
    skillsFindAllData &&
    skillsFindAllData?.data?.list?.map((skill) => {
      return { label: skill?.skill, key: skill?.skill, value: skill?.id };
    });

  const clients =
    clientsFindAllData &&
    clientsFindAllData?.data?.list?.map((client) => {
      return { label: client?.company_name, value: client?.id };
    });

  // set tooltip on table cell
  useEffect(() => {
    if (!positionsFindAllProcess && !positionsFindAllError && positionsFindAllData) {
      setToolTipKey(() => ['position', 'application_count']);
    }
  }, [positionsFindAllProcess, positionsFindAllError, positionsFindAllData]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
    dispatch(positionsFindAll(limit * (value - 1), limit, filterValues));
  };

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const positionId = data.row[0].value;

    // view only on position click
    positionId && data.action?.key === 'position' && history.push(`/positions/view/${positionId}`);

    // redirect to applicant
    if (data?.action?.key === 'application_count') {
      if (data?.row[6]?.value > 0) {
        const companyId = data.row[1].value;
        const position = data.row[3].value;

        history.push({
          pathname: `/applicants`,
          search: `?position=${position}&client=${companyId}`
        });
        return false;
      }
    }

    // Edit action
    if (positionId && data?.action?.type === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-position') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/positions/edit/${positionId}`);
    }

    // open action
    positionId &&
      data?.action?.type === 'open' &&
      data.row[12]?.value &&
      window.open(data.row[12]?.value);

    // Delete action
    if (positionId && data.action.type === 'delete') {
      if (commonFn.menuHiddenKeys.indexOf('delete-position') !== -1) {
        commonFn.showPopup(commonFn.deleteAccessError);
        return false;
      }

      MySwal.fire({
        title: 'Are you sure?',
        text: 'You want to delete the position',
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        result = result.isConfirmed && dispatch(positionDelete(positionId));

        result &&
          Swal.fire({
            title: 'Success!',
            text: 'Position has been deleted',
            icon: 'success',
            iconColor: '#3085d6',
          });
      });
    }
  };

  // refresh positions data after deleting
  useEffect(() => {
    !positionDeleteProcess &&
      positionDeleteData &&
      dispatch(positionsFindAll(skip, limit, filterAppliedData));
  }, [positionDeleteData]);

  const response = positionsFindAllData?.data?.list || positionsFilterData?.data?.list;
  const list =
    response &&
    response.map((item) => {
      const skill =
        item.skills &&
        item.skills.split(',').map((value) => {
          return { skill: value };
        });
      const position_types =
        item.position_type &&
        item.position_type.split(',').map((value) => {
          return { position_type: value };
        });
      return { ...item, skills: skill, position_types: position_types };
    });

  // hide action based on the permission
  useEffect(() => {
    if (!positionsFindAllProcess && !positionsFindAllError && positionsFindAllData) {
      if (commonFn.menuHiddenKeys.indexOf('edit-position') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Edit']);
      }
      if (commonFn.menuHiddenKeys.indexOf('delete-position') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Delete']);
      }
    }
  }, [positionsFindAllProcess, positionsFindAllError, positionsFindAllData]);

  // Mapping API response
  const tableData = {
    tableClass: 'positions-table',
    toolTipKey: toolTipKey,
    hiddenAction: hiddenAction,
    count: positionsFindAllData?.data?.count,
    skip: skip,
    data: list,
    head: PositionHead,
    pagination: {
      numberOfPages:
        (positionsFilterData && positionsFilterData?.data?.numberOfPages) ||
        positionsFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    clickOnCell: clickOnCell,
    errorMessage:
      (positionsFilterError && positionsFilterError?.message) || positionsFindAllError?.message,
  };

  // Filter Data
  const filterData = {
    filter: MainFilter,
    options: { position_type, skills, clients, min_exp_type: expType, max_exp_type: expType },
  };

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const changeEvent = (data) => {
    const filterArray = {};

    if (data?.field?.name === 'position_type') {
      const position_type = commonFn.getIdFromMultiValue(data?.value);
      filterArray.position_type_id = position_type;
    }

    if (data?.field?.name === 'skills') {
      const skills = commonFn.getIdFromMultiValue(data?.value);
      filterArray.skill_id = skills;
    }

    if (data?.field?.name === 'clients') {
      const clients = commonFn.getIdFromMultiValue(data?.value);
      filterArray.client_id = clients;
    }

    if (data?.field?.name === 'min_exp_type') {
      const min_exp_type = data?.value?.label;
      filterArray.min_exp_type = min_exp_type;
    }

    if (data?.field?.name === 'max_exp_type') {
      const max_exp_type = data?.value?.label;
      filterArray.max_exp_type = max_exp_type;
    }

    setFilterValues((filterValue) => ({
      ...filterValue,
      ...filterArray,
    }));
  };

  // Filter form submit
  const formSubmit = (data) => {
    const request = data;
    request.client_id = filterValues.client_id;
    request.position_type_id = filterValues.position_type_id;
    request.skill_id = filterValues.skill_id;
    request.min_exp_type = filterValues.min_exp_type;
    request.max_exp_type = filterValues.max_exp_type;

    const minSalary = request && request?.min_salary;
    const maxSalary = request && request?.max_salary;

    if (minSalary > maxSalary) {
      return dispatch(
        openSnackBarLayout('Minimum salary must be less than Maximum salary', 'error', 2000),
      );
    }
    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(positionsFindAll(0, limit, { ...filterValues, ...request }));
  };

  return (
    <div>
      <TabCustom {...tabs} />
      <>
        <Box mt={2} mb={4}>
          <FilterCustom
            {...filterData}
            formSubmit={formSubmit}
            changeEvent={changeEvent}
            hiddenKeys={filterHiddenKeys}
          />
        </Box>

        {positionsFindAllProcess && (
          <div className="align-center mt-36">
            <CircularProgress />
          </div>
        )}

        {!positionsFindAllProcess && (
          <Box mb={4}>
            <TableCustom {...tableData} hiddenKeys={hiddenKeys} />
          </Box>
        )}
      </>
    </div>
  );
};
