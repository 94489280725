import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Accounts } from '.';
import { ViewInvoice, AddOrEditInvoice } from '../../common';
import { NotFound } from '../../common';

export const AccountsRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/company/invoices'} component={Accounts} />
        <Route
          path={'/company/invoices/view/:id'}
          render={(props) => <ViewInvoice isView={true} {...props} />}
        />
        <Route
          path={'/company/invoices/edit/:id'}
          render={(props) => <AddOrEditInvoice isEdit={true} {...props} />}
        />

        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
