import { Types } from './types';

const initState = {
  locationsFindAllProcess: false,
  locationsFindAllError: null,
  locationsFindAllData: null,
};

export const locations = (state = initState, action) => {
  switch (action.type) {
    case Types.START_LOCATIONS_FIND_ALL:
      return {
        ...state,
        locationsFindAllProcess: true,
        locationsFindAllError: null,
        locationsFindAllData: null,
      };
    case Types.END_LOCATION_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        locationsFindAllProcess: false,
        locationsFindAllError: error,
        locationsFindAllData: success,
      };
    }
    default:
      return state;
  }
};
