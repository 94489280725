import React, { useState, useEffect } from 'react';
import { FormCustom, TabCustom } from '../../common';
import { FormInputsData } from '../../../data/EditLeaveData';
import { EmployeeTabs } from '../../../data/LeavesData';
import { commonFn } from '../../../util/commonFn';

export const AddOrEditLeave = ({ isEdit, isView, match, options = {}, values = {} }) => {
  const [formData, setFormData] = useState({});
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);

  // set hidden key in form inputs
  useEffect(() => {
    if (FormInputsData) {
      setHiddenKeys(() => ['employee_detail', 'status_id', 'comment']);
      setReadOnlyKeys(() => ['total_days']);
    }
  }, [FormInputsData]);

  // tabs
  const tabs = {
    ...EmployeeTabs,
    list: commonFn.updateLink(EmployeeTabs?.list, match),
  };

  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    }));
    // setIsRouteChange(true);
    // match?.params?.id && dispatch(employeeFind(match?.params?.id));
    // dispatch(positionTypesFindAll());
    // dispatch(citiesFindAll());
    // dispatch(statesFindAll());
    // dispatch(countriesFindAll());
    // dispatch(clientsFindAll());
    // setTimeout(() => setIsRouteChange(false), 50);
    // setIsFormSubmitted(() => false);
    // dispatch(documentTypesFindAll(empDocumentGroupKey));
  }, [match?.params?.id]);

  return (
    <>
      <TabCustom {...tabs} />
      <FormCustom {...formData} hiddenKeys={hiddenKeys} readOnlyKeys={readOnlyKeys} />
    </>
  );
};
