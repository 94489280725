import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components/macro';

import LinkPrimaryButton from './LinkPrimaryButton';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';

// Link button media query
let LinkButtonWrapper = styled(Box)`
  
`;

// Start Main Function
export const DashboardHeader = ({ header = [] }) => {
  const location = useLocation();

  return (
    <div className={header.className}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Box mt={0} mb={1}>
            <h2> {header?.page_title} </h2>
          </Box>

          {/* Breadcrumb */}
          <Breadcrumbs aria-label="breadcrumb">
            {header?.breadcrumb?.map(
              (value, index) =>
                (value.link && (
                  <Link color="inherit" key={index} href={value?.link} className={value?.className}>
                    {value?.text}
                  </Link>
                )) || <span key={index}>{value?.text}</span>,
            )}
          </Breadcrumbs>
          {/* End: Breadcrumb */}
        </Grid>

        {header?.button?.text && (
          <>
            {location?.pathname !== header?.button?.url &&
              commonFn.menuHiddenKeys.indexOf(header.button.hiddenKey) === -1 && (
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <LinkButtonWrapper className="link-button-wrapper" pt={2} textAlign="right">
                    <LinkPrimaryButton
                      href={header?.button?.url}
                      hide={header?.button?.hide}
                      className={header?.buttonClass}
                      text={header?.button?.text}
                    ></LinkPrimaryButton>
                  </LinkButtonWrapper>
                </Grid>
              )}
          </>
        )}
      </Grid>
    </div>
  );
};
