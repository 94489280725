import React from 'react';
import { Tabs, LeavesHead, ResponseData } from '../../../data/LeavesData';
import { TabCustom, TableCustom, Leaves } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


export const CompanyLeaves = () => {
  return (
    <>
      <Leaves />
    </>
  );
};
