import { postService, getService, deleteService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Positions Find All
const startPositionsFindAll = () => ({
  type: Types.START_POSITIONS_FIND_ALL,
  payload: {},
});

const endPositionsFindAll = (success, error) => ({
  type: Types.END_POSITIONS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const positionsFindAll =
  (skip = 0, limit = 10, data) =>
  async (dispatch) => {
    dispatch(startPositionsFindAll());
    const [response, error] = await postService(
      `${apiList.positionsFilter}?skip=${skip}&limit=${limit}`,
      data,
    );
    dispatch(endPositionsFindAll(response, error));
  };

// Create Position
const startCreatePosition = () => ({
  type: Types.START_CREATE_POSITION,
  payload: {},
});

const endCreatePosition = (success, error) => ({
  type: Types.END_CREATE_POSITION,
  payload: {
    success,
    error,
  },
});

export const createPosition = (data) => async (dispatch) => {
  dispatch(startCreatePosition());
  const [response, error] = await postService(apiList.positions, data);
  dispatch(endCreatePosition(response, error));
};

// Find Position
const startPositionFind = () => ({
  type: Types.START_POSITION_FIND,
  payload: {},
});

const endPositionFind = (success, error) => ({
  type: Types.END_POSITION_FIND,
  payload: {
    success,
    error,
  },
});

export const positionFind = (id) => async (dispatch) => {
  dispatch(startPositionFind());
  const [response, error] = await getService(`${apiList.positions}/${id}`);
  dispatch(endPositionFind(response, error));
};

// Find positions by client id
const startPositionFindAllByClient = () => ({
  type: Types.START_POSITION_FIND_ALL_BY_CLIENT,
  payload: {},
});

const endPositionFindAllByClient = (success, error) => ({
  type: Types.END_POSITION_FIND_ALL_BY_CLIENT,
  payload: {
    success,
    error,
  },
});

export const positionByClient = (id) => async (dispatch) => {
  dispatch(startPositionFindAllByClient());
  const [response, error] = await getService(`${apiList.positionsByClient}/${id}`);
  dispatch(endPositionFindAllByClient(response, error));
};

// Delete Position
const startPositionDelete = () => ({
  type: Types.START_POSITION_DELETE,
  payload: {},
});

const endPositionDelete = (success, error) => ({
  type: Types.END_POSITION_DELETE,
  payload: {
    success,
    error,
  },
});

export const positionDelete = (id) => async (dispatch) => {
  dispatch(startPositionDelete());
  const [response, error] = await deleteService(`${apiList.positions}/${id}`);
  dispatch(endPositionDelete(response, error));
};

// Position Update
const startUpdatePosition = () => ({
  type: Types.START_POSITION_UPDATE,
  payload: {},
});

const endUpdatePosition = (success, error) => ({
  type: Types.END_POSITION_UPDATE,
  payload: {
    success,
    error,
  },
});

export const updatePosition = (id, data) => async (dispatch) => {
  dispatch(startUpdatePosition());
  const [response, error] = await putService(`${apiList.positions}/${id}`, data);
  dispatch(endUpdatePosition(response, error));
};
