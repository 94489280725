import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Invoices } from '../../common';
import { Tabs, AccountsHead, ResponseData } from '../../../data/AccountsData';
import { TabCustom, TableCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { findAllInvoices } from '../../../redux/invoices/action';

export const Accounts = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const { invoiceFindAllProcess, invoiceFindAllError, invoiceFindAllData } = useSelector(
    ({ invoices }) => ({
      ...invoices,
    }),
  );

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Company | Accounts';
  }, []);

  useEffect(() => {
    dispatch(findAllInvoices(skip, limit));
  }, [skip]);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const invoicesResponse = invoiceFindAllData && invoiceFindAllData?.data?.list;
  const invoiceData =
    invoicesResponse &&
    invoicesResponse.map((invoice) => {
      return { ...invoice };
    });

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllInvoices(limit * (value - 1), limit));
  };

  // table data
  const tableData = {
    tabs: tabs,
    module: 'company',
    count: invoiceFindAllData?.data?.count,
    skip: skip,
    data: invoiceData,
    head: AccountsHead,
    pagination: {
      numberOfPages: invoiceFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: invoiceFindAllError?.message,
    hiddenKeys: ['company_logo', 'actions', 'created_by_user_name'],
    inProgress: invoiceFindAllProcess ? true : false,
  };

  return <>{!invoiceFindAllProcess && <Invoices {...tableData} />}</>;
};
