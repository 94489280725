import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import { Button } from '@material-ui/core';

const ButtonStyled = styled(Button)`
  background-color: ${colors.primary.black90} !important;
  border-color: ${colors.primary.black90} !important;
  color: ${colors.white} !important;
  text-transform: capitalize !important;
  padding: 0.25rem 0.5rem !important;
  line-height: 1.5 !important;
  height: 38px !important;
  font-size: 14px !important;
  margin-top: 3px !important;
  border-radius: 0.2rem !important;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%) !important;
  }
  &.view-close {
    background-color: ${colors.white} !important;
    color: ${colors.primary.black90} !important;
  }
`;

export const PrimaryButton = (props) => {
  return (
    <ButtonStyled
      variant="outlined"
      type={props?.type || 'submit'}
      hidden={props?.hidden}
      className={props.class}
    >
      {props?.text}
    </ButtonStyled>
  );
};

export default PrimaryButton;
