import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const permissions = [
  { slug: 'delete-client', permission: 'Delete Client', show: 'clients' },
  { slug: 'create-client', permission: 'Create Client', show: 'clients' },
  { slug: 'list-clients', permission: 'List Clients', show: 'clients' },
  { slug: 'edit-client', permission: 'Edit Client', show: 'clients' },

  { slug: 'create-user', permission: 'Create User', show: 'users' },
  { slug: 'list-users', permission: 'List Users', show: 'users' },
  { slug: 'edit-user', permission: 'Edit User', show: 'users' },
  { slug: 'delete-user', permission: 'Delete User', show: 'users' },

  { slug: 'create-applicant', permission: 'Create Applicant', show: 'applicants' },
  { slug: 'list-applicants', permission: 'List Applicants', show: 'applicants' },
  { slug: 'edit-applicant', permission: 'Edit Applicant', show: 'applicants' },
  { slug: 'delete-applicant', permission: 'Delete Applicant', show: 'applicants' },

  { slug: 'create-position', permission: 'Create Position', show: 'positions' },
  { slug: 'list-positions', permission: 'List Positions', show: 'positions' },
  { slug: 'edit-position', permission: 'Edit Position', show: 'positions' },
  { slug: 'delete-position', permission: 'Delete Position', show: 'positions' },
];

export const commonFn = {
  // Tab link
  menuHiddenKeys: [],
  permissions: permissions,
  editAccessError: `You don't have access to edit it`,
  deleteAccessError: `You don't have access to delete it`,
  updateLink: (list, match) => {
    const listTab = [];
    const createObj = list.find((tab) => 'create' === tab.key);

    list?.map((tab) => {
      if (['edit', 'view', 'create'].indexOf(tab.key) > -1) {
        if (tab?.path === match?.path) {
          tab.link = match?.url;
          listTab.push(tab);
        }
      } else {
        listTab.push(tab);
      }
    });

    if (listTab.length === 1) {
      listTab.unshift(createObj);
    }

    return listTab;
  },

  // Get id from multi select value
  getIdFromMultiValue: (value) => {
    return (
      (value && Array.isArray(value) && value.map((item) => item.value).join(',')) || value || ''
    );
  },

  getIdfromSingleValue: (value, array, key = 'label') => {
    return Array.isArray(array) && array.find((item) => item[key] === value);
  },

  // get multi value from id
  getMultiValueFromId: (ids = '', options = []) => {
    try {
      return options.filter(
        (option) =>
          (ids && ids.toString().split(',').indexOf(option.value.toString()) !== -1) || false,
      );
    } catch (e) {
      return [];
    }
  },

  // chek user permissions
  checkUserMenuPermissions: (userPermissionSlug = []) => {
    try {
      // split comma separated values
      const permissionsSlugArray = userPermissionSlug.split(',');

      // new array with combined values
      const result = [];
      for (let i = 0; i < permissionsSlugArray.length; i++) {
        result.push({ slug: permissionsSlugArray[i] });
      }

      // check if user permission is in permission list
      const userPermissionResult = [];
      permissions.find((permission, index) => {
        result.map((obj) => {
          if (permission?.slug === obj?.slug) {
            userPermissionResult[index] = {
              slug: obj?.slug,
              permission: permission?.permission,
              show: permission?.show,
            };
            return true;
          }
        });
      });
      return userPermissionResult;
    } catch (e) {
      return [];
    }
  },

  showPopup: (text, title, icon, iconColor) => {
    MySwal.fire({
      title: title || 'Info',
      text: text || `You don't have access`,
      icon: icon || 'warning',
      iconColor: iconColor || '#3085d6',
    });
  },


  // get day from date
  getDayFromDate: (dateString) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    return days[date.getDay()];
  }
};
