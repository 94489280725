import React from 'react';
import { ProfileRouter } from '.';
import { DashboardHeader } from '../../common';
import { PageHeader } from '../../../data/CompanyProfileData';
import { Container } from '@material-ui/core';

export const CompanyProfileMain = () => {
  const headerData = { header: PageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ProfileRouter />
    </Container>
  );
};
