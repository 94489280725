export const Types = {
    START_POSITIONS_FIND_ALL: 'START_POSITIONS_FIND_ALL',
    END_POSITIONS_FIND_ALL: 'END_POSITIONS_FIND_ALL',

    START_CREATE_POSITION: 'START_CREATE_POSITION',
    END_CREATE_POSITION: 'END_CREATE_POSITION',
    
    START_POSITION_FIND: 'START_POSITION_FIND',
    END_POSITION_FIND: 'END_POSITION_FIND',

    START_POSITION_FIND_ALL_BY_CLIENT: 'START_POSITION_FIND_ALL_BY_CLIENT',
    END_POSITION_FIND_ALL_BY_CLIENT: 'END_POSITION_FIND_ALL_BY_CLIENT',

    START_POSITION_DELETE: 'START_POSITION_DELETE',
    END_POSITION_DELETE: 'END_POSITION_DELETE',

    START_POSITION_UPDATE: 'START_POSITION_UPDATE',
    END_POSITION_UPDATE: 'END_POSITION_UPDATE'
};