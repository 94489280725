import { getService, postService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Skills Find All
const startSkillsFindAll = () => ({
  type: Types.START_SKILLS_FIND_ALL,
  payload: {},
});

const endSkillsFindAll = (success, error) => ({
  type: Types.END_SKILLS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const skillsFindAll = () => async (dispatch) => {
  dispatch(startSkillsFindAll());
  const [response, error] = await getService(`${apiList.skills}`);
  dispatch(endSkillsFindAll(response, error));
};

// Create Skill
const startCreateSkill = () => ({
  type: Types.START_CREATE_SKILL,
  payload: {},
});

const endCreateSkill = (success, error) => ({
  type: Types.END_CREATE_SKILL,
  payload: {
    success,
    error,
  },
});

export const createSkill = (data) => async (dispatch) => {
  dispatch(startCreateSkill());
  const [response, error] = await postService(apiList.skills, data);
  dispatch(endCreateSkill(response, error));
};
