import envDev from '../env.dev.json';
import envProd from '../env.prod.json';

const config = process.env.REACT_APP_ENV;

let envConfig;

switch (config) {
  case 'production':
    envConfig = envProd;
    break;
  default:
    envConfig = envDev;
}
export const get = (params) => {
  return envConfig[params];
};

export const host = () => {
  return get('HOST');
};

export const baseApiServer = (params) => {
  return get('API_SERVER')[params];
};
