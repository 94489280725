import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Employee Detail',
    groupLabelClass: 'fieldset-label',
    key: 'employee_detail',
    hiddenKey: 'employee_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Employee ID',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'employee_id',
        name: 'employee_id',
        placeholder: 'Employee ID',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'First Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Flipkoins Email',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'flipkoins_email',
        name: 'flipkoins_email',
        placeholder: 'Flipkoins Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Company',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company',
        name: 'company',
        placeholder: 'Company',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
  {
    groupLabel: 'Leave Detail',
    groupLabelClass: 'fieldset-label',
    key: 'leave_detail',
    name: 'leave_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Leave Type',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'leave_type',
        name: 'leave_type',
        placeholder: 'Leave Type',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'From',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'from',
        name: 'from',
        placeholder: 'From',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'To',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'to',
        name: 'to',
        placeholder: 'To',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'No. of Days',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'total_days',
        name: 'total_days',
        placeholder: 'No. of Days',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Approver',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'approver',
        name: 'approver',
        placeholder: 'Approver',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Status',
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        key: 'status_id',
        name: 'status_id',
        hiddenKey: 'status_id',
        placeholder: 'Status',
        hide: false,
        optionsKey: 'status',
        valueId: 'status_id',
        displayKey: 'status',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        options: [],
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Select the status' }],
      },
      {
        label: 'Comment/Reason',
        xs: 12,
        sm: 12,
        md: 8,
        lg: 8,
        xl: 8,
        key: 'comment',
        name: 'comment',
        hiddenKey: 'comment',
        placeholder: 'Comment/Reason',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
];
