import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AccountsInvoicesMain } from './invoices';
import { NotFound } from '../common';

export const AccountsLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/accounts/invoices`} component={AccountsInvoicesMain} />
        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
