export const Types = {
  START_CREATE_EMPLOYEE: 'START_CREATE_EMPLOYEE',
  END_CREATE_EMPLOYEE: 'END_CREATE_EMPLOYEE',

  START_EMPLOYEE_FIND_ALL: 'START_EMPLOYEE_FIND_ALL',
  END_EMPLOYEE_FIND_ALL: 'END_EMPLOYEE_FIND_ALL',

  START_EMPLOYEE_FIND: 'START_EMPLOYEE_FIND',
  END_EMPLOYEE_FIND: 'END_EMPLOYEE_FIND',

  START_EMPLOYEE_UPDATE: 'START_EMPLOYEE_UPDATE',
  END_EMPLOYEE_UPDATE: 'END_EMPLOYEE_UPDATE'
};
