import React, { useEffect, useState } from 'react';
import { Invoices } from '../../common';
import { InvoiceTabs, ResponseData, AccountsHead } from '../../../data/AccountsData';
import { commonFn } from '../../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { findAllInvoices } from '../../../redux/invoices/action';

export const AccountsInvoices = (match) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const { invoiceFindAllProcess, invoiceFindAllError, invoiceFindAllData } = useSelector(
    ({ invoices }) => ({
      ...invoices,
    }),
  );

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Accounts | Invoices';
  }, []);

  useEffect(() => {
    dispatch(findAllInvoices(skip, limit));
  }, [skip]);

  const tabs = {
    ...InvoiceTabs,
    list: commonFn.updateLink(InvoiceTabs.list, match),
  };

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllInvoices(limit * (value - 1), limit));
  };

  const invoicesResponse = invoiceFindAllData && invoiceFindAllData?.data?.list;
  const invoiceData =
    invoicesResponse &&
    invoicesResponse.map((invoice) => {
      return { ...invoice };
    });

  // table data
  const tableData = {
    tabs: tabs,
    module: 'accounts',
    count: invoiceFindAllData?.data?.count,
    skip: skip,
    data: invoiceData,
    head: AccountsHead,
    pagination: {
      numberOfPages: invoiceFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: invoiceFindAllError?.message,
    hiddenKeys: [],
    inProgress: invoiceFindAllProcess ? true : false,
  };

  return (
    <>
      <Invoices {...tableData} />
    </>
  );
};
