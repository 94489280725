import { getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Permissions
const startPermissionsFindAll = () => ({
  type: Types.START_PERMISSIONS_FIND_ALL,
  payload: {},
});

const endPermissionsFindAll = (success, error) => ({
  type: Types.END_PERMISSIONS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

// Permissions Find All
export const permissionsFindAll = () => async (dispatch) => {
  dispatch(startPermissionsFindAll());
  const [response, error] = await getService(`${apiList.permissions}`);
  dispatch(endPermissionsFindAll(response, error));
};

// Position Types
const startPositionTypesFindAll = () => ({
  type: Types.START_POSITION_TYPES_FIND_ALL,
  payload: {},
});

const endPositionTypesFindAll = (success, error) => ({
  type: Types.END_POSITION_TYPES_FIND_ALL,
  payload: {
    success,
    error,
  },
});

// position types API handling
export const positionTypesFindAll = () => async (dispatch) => {
  dispatch(startPositionTypesFindAll());
  const [response, error] = await getService(`${apiList.positionTypes}`);
  dispatch(endPositionTypesFindAll(response, error));
};

// Timezones
const startTimezonesFindAll = () => ({
  type: Types.START_FIND_ALL_TIMEZONES,
  payload: {},
});

const endTimezonesFindAll = (success, error) => ({
  type: Types.END_FIND_ALL_TIMEZONES,
  payload: {
    success,
    error,
  },
});

// timezones types API handling
export const timezonesFindAll = () => async (dispatch) => {
  dispatch(startTimezonesFindAll());
  const [response, error] = await getService(`${apiList.timezones}`);
  dispatch(endTimezonesFindAll(response, error));
};

// Invoice status
const startInvoiceStatusFindAll = () => ({
  type: Types.START_FIND_ALL_INVOICE_STATUS,
  payload: {},
});

const endInvoiceStatusFindAll = (success, error) => ({
  type: Types.END_FIND_ALL_INVOICE_STATUS,
  payload: {
    success,
    error,
  },
});

// invoice status find all
export const invoiceStatusFindAll = () => async (dispatch) => {
  dispatch(startInvoiceStatusFindAll());
  const [response, error] = await getService(`${apiList.invoiceStatus}`);
  dispatch(endInvoiceStatusFindAll(response, error));
};
