export const Types = {
  START_USERTYPES_FIND_ALL: 'START_USERTYPES_FIND_ALL',
  END_USERTYPES_FIND_ALL: 'END_USERTYPES_FIND_ALL',

  START_USERROLES_FIND_ALL: 'START_USERROLES_FIND_ALL',
  END_USERROLES_FIND_ALL: 'END_USERROLES_FIND_ALL',

  START_PERMISSIONS_FIND_ALL: 'START_PERMISSIONS_FIND_ALL',
  END_PERMISSIONS_FIND_ALL: 'END_PERMISSIONS_FIND_ALL',

  START_POSITION_TYPES_FIND_ALL: 'START_POSITION_TYPES_FIND_ALL',
  END_POSITION_TYPES_FIND_ALL: 'END_POSITION_TYPES_FIND_ALL',

  START_FIND_ALL_TIMEZONES: 'START_FIND_ALL_TIMEZONES',
  END_FIND_ALL_TIMEZONES: 'END_FIND_ALL_TIMEZONES',

  START_FIND_ALL_INVOICE_STATUS: 'START_FIND_ALL_INVOICE_STATUS',
  END_FIND_ALL_INVOICE_STATUS: 'END_FIND_ALL_INVOICE_STATUS',
};
