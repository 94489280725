import React, { useEffect } from 'react';
import { FormInputsData, FileUpload } from '../../data/CreatePositionData';
import { Tabs } from '../../data/PositionsData';
import { ViewFormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { positionFind } from '../../redux/positions/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const ViewPosition = ({ isEdit, isView, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { positionFindError, positionFindData, positionFindProcess } = useSelector(
    ({ positions }) => ({
      ...positions,
    }),
  );

  useEffect(() => {
    dispatch(positionFind(match?.params?.id));
  }, []);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const formData = {
    list: FormInputsData,
    tabs: tabs,
    inProgress: positionFindProcess,
    error:
      (positionFindData?.data === null && positionFindData?.message) || positionFindError?.message,
    isEdit,
    isView,
    value: positionFindData?.data,
  };

  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-position') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/positions/edit/${match?.params?.id}`);
    } else if (value?.button?.key === 'close') {
      history.push(`/positions`);
    }
  };

  const UploadJD = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={FileUpload?.xs || 12}
            sm={FileUpload?.sm || 12}
            md={FileUpload?.md || 6}
            lg={FileUpload?.lg || 4}
            xl={FileUpload?.xl || 4}
          >
            <Box className={FileUpload?.formElementClass}>
              <label className="interview-label">{FileUpload.label}</label>
              {formData.value?.job_description && (
                <div className="file-section">
                  <a
                    href={formData.value?.job_description}
                    target="_blank"
                    className={FileUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.job_description_original}
                  </a>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} renderer={{ UploadJD }} />
    </>
  );
};

export default ViewPosition;
