import { Types } from './types';

const initState = {
  positionsFilterProcess: false,
  positionsFilterError: null,
  positionsFilterData: null,
};

export const positionsFilter = (state = initState, action) => {
  switch (action.type) {
    // case positions filter
    case Types.START_POSITION_FILTER:
      return {
        ...state,
        positionsFilterProcess: true,
        positionsFilterError: null,
        positionsFilterData: null,
      };
    case Types.END_POSITION_FILTER: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionsFilterProcess: false,
        positionsFilterError: error,
        positionsFilterData: success,
      };
    }
    default:
      return state;
  }
};
