const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '480px',
  tablet: '640px',
  tabletM: '800px',
  laptop: '960px',
  laptopM: '1280px',
  laptopL: '1440px',
  laptopXL: '1920px',
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletM: `(min-width: ${size.tabletM})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopXL: `(min-width: ${size.laptopXL})`,
};

export const bp = {
  sm: device.mobile,
  md: device.tablet,
  lg: device.laptop,
  xl: device.laptopM,
};

export const colors = {
  black: '#000',
  white: '#FFF',
  transparent: 'transparent',
  lightversion: '#f1f4f6',
  sidebarbg: 'rgba(24,139,246,.1)',

  primary: {
    navy25: '#C3BFD6',
    navy50: '#8780AE',
    navy75: '#4C4085',
    navy100: '#10005D',

    black25: '#333333',
    black50: '#666666',
    black75: '#999999',
    black90: '#010101',

    blue25: '#C3CCE5',
    blue50: '#8898CB',
    blue75: '#4C65B1',
    blue100: '#113297',

    orange25: '#FFD2CF',
    orange50: '#FFA5A0',
    orange75: '#FF7870',
    orange100: '#FF4B41',

    red25: '#ECC7C9',
    red50: '#D98E92',
    red75: '#C7565C',
    red100: '#B41E26',

    white70: 'rgba(255, 255, 255, 0.7)',
    white20: 'rgba(255, 255, 255, 0.2)',

    redp1: '#D72850',
    redp2: '#FF465F',
    redp3: '#FF9191',
    red70p3: 'rgba(255, 145, 145, 0.7)',

    n1: '#333333',
    n2: '#A5A5A5',
    n5: '#EEEEEE',

    yellowp1: '#FFA014',
    yellowp2: '#FFB41E',
    yellowp3: '#FFDC78',
    yellowp4: '#C05C27',

    yellow100: '#FCC227',

  },

  accent: {
    gray25: '#EFEFEC',
    gray50: '#DFDFD9',
    gray75: 'D0D0C6',
    gray100: 'C0C0B3',

    sand25: '#F5EEE6',
    sand50: '#EBDCCD',
    sand75: '#E2CBB5',
    sand100: '#D8BA9C',

    green25: '#E6EDE5',
    green50: '#CDDBCA',
    green75: '#B4C9B0',
    green100: '#9BB796',

    teal25: '#E5EBF4',
    teal50: '#CBD7E9',
    teal75: '#B1C3DF',
    teal100: '#97AFD4',

    liliac25: '#EDE8F7',
    liliac50: '#DBD0EF',
    liliac75: '#C9B9E8',
    liliac100: '#B7A2E0',

    pink25: '#FAEAED',
    pink50: '#F4D6DB',
    pink75: '#EFC1CA',
    pink100: '#EAADB8',

    yellow25: '#F8F0DC',
    yellow50: '#F1E2B9',
    yellow75: '#EBD396',
    yellow100: '#E4C573',
  },
};
