import { postService, getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

const startLogin = () => ({
  type: Types.START_LOGIN,
  payload: {},
});

const endLogin = (success, error) => ({
  type: Types.END_LOGIN,
  payload: {
    success,
    error,
  },
});

export const postLogin = (params) => async (dispatch) => {
  dispatch(startLogin());
  const [response, error] = await postService(apiList.login, params);
  dispatch(endLogin(response, error));
};

const startForgotPassword = () => ({
  type: Types.START_FORGOT_PASSWORD,
  payload: {},
});

const endForgotPassword = (success, error) => ({
  type: Types.END_FORGOT_PASSWORD,
  payload: {
    success,
    error,
  },
});

export const postForgotPassword = (params) => async (dispatch) => {
  dispatch(startForgotPassword());
  const [response, error] = await postService(apiList.forgotPassword, params);
  dispatch(endForgotPassword(response, error));
};

const startResetPassword = () => ({
  type: Types.START_RESET_PASSWORD,
  payload: {},
});

const endResetPassword = (success, error) => ({
  type: Types.END_RESET_PASSWORD,
  payload: {
    success,
    error,
  },
});

export const postResetPassword = (params, search) => async (dispatch) => {
  dispatch(startResetPassword());
  const [response, error] = await postService(apiList.resetPassword + search, params);
  dispatch(endResetPassword(response, error));
};

const startVerify = () => ({
  type: Types.START_VERIFY,
  payload: {},
});

const endVerify = (success, error) => ({
  type: Types.END_VERIFY,
  payload: { success, error },
});

export const postVerify = (search) => async (dispatch) => {
  dispatch(startVerify());
  const [response, error] = await getService(`${apiList.verify}${search}`);
  dispatch(endVerify(response, error));
};

const startIsAuth = () => ({
  type: Types.START_IS_AUTH,
  payload: {},
});

const endIsAuth = (success, error) => ({
  type: Types.END_IS_AUTH,
  payload: { success, error },
});

export const isAuth = () => async (dispatch) => {
  dispatch(startIsAuth());
  const [response, error] = await getService(`${apiList.isAuth}`);
  dispatch(endIsAuth(response, error));
};

const startLogout = () => ({
  type: Types.START_LOGOUT,
  payload: {},
});

const endLogout = (success, error) => ({
  type: Types.END_LOGOUT,
  payload: {
    success,
    error,
  },
});

export const logout = () => async (dispatch) => {
  dispatch(startLogout());
  const [response, error] = await getService(apiList.logout);
  dispatch(endLogout(response, error));
};
