import { getService, postService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Interview Status Find All
const startInterviewStatusFindAll = () => ({
  type: Types.START_FIND_INTERVIEW_STATUS,
  payload: {},
});

const endInterviewStatusFindAll = (success, error) => ({
  type: Types.END_FIND_INTERVIEW_STATUS,
  payload: {
    success,
    error,
  },
});

export const interviewStatusFindAll = () => async (dispatch) => {
  dispatch(startInterviewStatusFindAll());
  const [response, error] = await getService(`${apiList.interview_status}`);
  dispatch(endInterviewStatusFindAll(response, error));
};

// Interview Status Find All
const startCreateInterviewStatus = () => ({
  type: Types.START_CREATE_INTERVIEW_STATUS,
  payload: {},
});

const endCreateInterviewStatus = (success, error) => ({
  type: Types.END_CREATE_INTERVIEW_STATUS,
  payload: {
    success,
    error,
  },
});

export const createInterviewStatus = (data) => async (dispatch) => {
  dispatch(startCreateInterviewStatus());
  const [response, error] = await postService(apiList.interview_status, data);
  dispatch(endCreateInterviewStatus(response, error));
};
