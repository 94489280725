import React from 'react';
import { Container } from '@material-ui/core';
import { PositionsRouter } from '.';
import { DashboardHeader} from '../common';
import { PageHeader } from '../../data/PositionsData';

export const PositionsMain = (props) => {
  const headerData = { header: PageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <PositionsRouter />
    </Container>
  );
};
