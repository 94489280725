import { postService, getService, deleteService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Applicants Find All
const startApplicantsFindAll = () => ({
  type: Types.START_APPLICANTS_FIND_ALL,
  payload: {},
});

const endApplicantsFindAll = (success, error) => ({
  type: Types.END_APPLICANTS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const applicantsFindAll =
  (skip = 0, limit = 10, data) =>
  async (dispatch) => {
    dispatch(startApplicantsFindAll());
    const [response, error] = await postService(
      `${apiList.applicantsFilter}?skip=${skip}&limit=${limit}`,
      data,
    );
    dispatch(endApplicantsFindAll(response, error));
  };

// Create Applicant
const startCreateApplicant = () => ({
  type: Types.START_CREATE_APPLICANT,
  payload: {},
});

const endCreateApplicant = (success, error) => ({
  type: Types.END_CREATE_APPLICANT,
  payload: {
    success,
    error,
  },
});

export const createApplicant = (data) => async (dispatch) => {
  dispatch(startCreateApplicant());
  const [response, error] = await postService(apiList.applicants, data);
  dispatch(endCreateApplicant(response, error));
};

// Find Applicant
const startApplicantFind = () => ({
  type: Types.START_APPLICANT_FIND,
  payload: {},
});

const endApplicantFind = (success, error) => ({
  type: Types.END_APPLICANT_FIND,
  payload: {
    success,
    error,
  },
});

export const applicantFind = (id) => async (dispatch) => {
  dispatch(startApplicantFind());
  const [response, error] = await getService(`${apiList.applicants}/${id}`);
  dispatch(endApplicantFind(response, error));
};

// Applicant Source
const startApplicantSourceFindAll = () => ({
  type: Types.START_APPLICANT_SOURCE_FIND_ALL,
  payload: {},
});

const endApplicantSourceFindAll = (success, error) => ({
  type: Types.END_APPLICANT_SOURCE_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const applicantSourceFindAll = () => async (dispatch) => {
  dispatch(startApplicantSourceFindAll());
  const [response, error] = await getService(`${apiList.applicantSources}`);
  dispatch(endApplicantSourceFindAll(response, error));
};

// Delete Position
const startApplicantDelete = () => ({
  type: Types.START_APPLICANT_DELETE,
  payload: {},
});

const endApplicantDelete = (success, error) => ({
  type: Types.END_APPLICANT_DELETE,
  payload: {
    success,
    error,
  },
});

export const applicantDelete = (id) => async (dispatch) => {
  dispatch(startApplicantDelete());
  const [response, error] = await deleteService(`${apiList.applicants}/${id}`);
  dispatch(endApplicantDelete(response, error));
};

// Update applicant
const startApplicantUpdate = () => ({
  type: Types.START_APPLICANT_UPDATE,
  payload: {},
});

const endApplicantUpdate = (success, error) => ({
  type: Types.END_APPLICANT_UPDATE,
  payload: {
    success,
    error,
  },
});

export const updateApplicant = (id, data) => async (dispatch) => {
  dispatch(startApplicantUpdate());
  const [response, error] = await putService(`${apiList.applicants}/${id}`, data);
  dispatch(endApplicantUpdate(response, error));
};

// Create Source
const startCreateSource = () => ({
  type: Types.START_SOURCE_CREATE,
  payload: {},
});

const endCreateSource = (success, error) => ({
  type: Types.END_SOURCE_CREATE,
  payload: {
    success,
    error,
  },
});

export const createSource = (data) => async (dispatch) => {
  dispatch(startCreateSource());
  const [response, error] = await postService(apiList.applicantSources, data);
  dispatch(endCreateSource(response, error));
};
