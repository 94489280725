import React, { useEffect, useState } from 'react';
import {
  FormInputsData,
  InterviewInput,
  FileUpload,
  ClientCVUpload,
} from '../../data/CreateApplicantData';
import { SkillsData, Tabs } from '../../data/ApplicantsData';
import { FormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { skillsFindAll, createSkill } from '../../redux/skills/action';
import { clientsFindAll } from '../../redux/clients/action';
import { positionByClient } from '../../redux/positions/action';
import { useHistory } from 'react-router';
import {
  createApplicant,
  applicantSourceFindAll,
  applicantFind,
  updateApplicant,
  createSource,
} from '../../redux/applicants/action';
import { openSnackBarLayout } from '../../redux/common/action';
import {
  CircularProgress,
  Grid,
  TextField,
  Link,
  Box,
  FormControl,
  TextareaAutosize,
} from '@material-ui/core';
import './CreateApplicant.scss';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CreatableSelect from 'react-select/creatable';
import { createInterviewStatus, interviewStatusFindAll } from '../../redux/interview_status/action';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCountry,
  createState,
  createCity,
} from '../../redux/cities/action';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const CreateApplicant = ({ isEdit, isView, match, options = {}, values = {} }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  // create applicant
  const {
    skillsFindAllProcess,
    skillsFindAllError,
    skillsFindAllData,
    createApplicantProcess,
    createApplicantError,
    createApplicantData,
    applicantSourceFindAllProcess,
    applicantSourceFindAllError,
    applicantSourceFindAllData,
    applicantFindError,
    applicantFindData,
    applicantFindProcess,
    updateApplicantProcess,
    updateApplicantError,
    updateApplicantData,
    positionFindAllByClientData,
    positionFindAllByClientProcess,
    positionFindAllByClientError,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    skillCreateProcess,
    skillCreateError,
    skillCreateData,
    interviewStatusFindProcess,
    interviewStatusFindError,
    interviewStatusFindData,
    interviewStatusCreateProcess,
    interviewStatusCreateError,
    interviewStatusCreateData,
    userInfo,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    sourceCreateProcess,
    sourceCreateError,
    sourceCreateData,
  } = useSelector(
    ({ positions, applicants, skills, clients, interviewStatus, sign, citiesNew }) => ({
      ...positions,
      ...applicants,
      ...skills,
      ...clients,
      ...interviewStatus,
      ...sign,
      ...citiesNew,
    }),
  );

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [updateChild, setUpdateChild] = useState(1);
  const [inputList, setInputList] = useState(InterviewInput);
  const [fileUpload, setFileUpload] = useState(FileUpload);
  const [clientCvUpload, setClientCvUpload] = useState(ClientCVUpload);

  const [isRouteChange, setIsRouteChange] = useState(false);
  const [formData, setFormData] = useState({});
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [aliasObject, setAliasObject] = useState({});
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // date
  const [dateTimeValue, setDateTimeValue] = useState();

  useEffect(() => {
    if (userInfo?.data?.type_slug === 'client') {
      setHiddenKeys((data) => [
        ...data,
        'current_ctc',
        'expected_ctc',
        'phone',
        'email',
        'linkedin',
        'upload_cv',
        'source_id',
        'internal_hr_comments',
      ]);
      if (
        userInfo?.data?.role_slug === 'user' &&
        userInfo?.data?.permissions_slug?.indexOf('show-client-ctc') === -1
      ) {
        setHiddenKeys((data) => [...data, 'client_ctc']);
      }

      setAliasObject(() => ({
        client_ctc_label: 'CTC',
        client_ctc_placeholder: 'CTC',
        client_cv_label: 'CV',
        client_cv_placeholder: 'Upload CV',
      }));

      setReadOnlyKeys(() => ['client_ctc']);

      if (!isEdit) {
        setHiddenKeys((data) => [...data, 'client_ctc']);
      }
    }

    if (
      userInfo?.data?.type_slug === 'super-admin' &&
      userInfo?.data?.role_slug === 'user' &&
      userInfo?.data?.permissions_slug?.indexOf('show-client-ctc') === -1
    ) {
      setHiddenKeys((data) => [...data, 'client_ctc']);
    }
  }, [userInfo]);

  useEffect(() => {
    setIsRouteChange(true);
    dispatch(skillsFindAll());
    dispatch(clientsFindAll());
    dispatch(applicantSourceFindAll());
    match?.params?.id && dispatch(applicantFind(match?.params?.id));
    dispatch(interviewStatusFindAll());
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());

    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    }));
    setInputList(() => InterviewInput);
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(false);
  }, [match.params.id]);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  // interview status change
  const onChangeValue = (data) => {
    if (data?.value && data?.value?.__isNew__) {
      if (data?.field?.statusName) {
        createNewInterviewStatus(data?.value?.value);
      }
    }
  };

  // date change event
  const onDateChangeValue = (date) => {
    // let convertedDate = JSON.stringify(date);
    // convertedDate = (convertedDate.slice(1,20).replace('T', ' '));
    // console.log('finaldate', convertedDate);

    setDateTimeValue(date);

    // setFormData((data) => ({
    //   ...data,
    //   values: {
    //     ...data.values,
    //     ...data.watch,
    //     interview_date: { convertedDate },
    //   },
    // }));
  };

  // create interview status
  const createNewInterviewStatus = (status) => {
    const statusData = {
      interview_status: status,
    };
    dispatch(createInterviewStatus(statusData));
  };

  // Country
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = formData.values?.country_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.country_id?.value, country).value
        : applicantFindData?.data?.country_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                country_id: commonFn.getMultiValueFromId(getId, country)[0],
              }
            : data.values,
        options: { ...data.options, country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, applicantFindData]);

  // City
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getId = formData.values?.city_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.city_id?.value, city).value
        : applicantFindData?.data?.city_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                city_id: commonFn.getMultiValueFromId(getId, city)[0],
              }
            : data.values,
        options: { ...data.options, city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, applicantFindData]);

  // State
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getId = formData.values?.state_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.state_id?.value, state).value
        : applicantFindData?.data?.state_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                state_id: commonFn.getMultiValueFromId(getId, state)[0],
              }
            : data.values,
        options: { ...data.options, state },
      }));
    }
  }, [stateFindAllProcess, stateFindAllError, stateFindAllData, applicantFindData]);

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh country after creating
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const countryValue = formData.values?.country_id;

      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            country_id: country,
          },
        }));
      }
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            state_id: state,
          },
        }));
      }
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  // Refresh city after creating new
  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const cityValue = formData.values?.city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            city_id: city,
          },
        }));
      }
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // create source onchange event
  const createNewSource = (source) => {
    const sourceData = {
      source: source,
    };
    dispatch(createSource(sourceData));
  };

  // Refresh source after creating new
  useEffect(() => {
    if (!sourceCreateProcess && !sourceCreateError && sourceCreateData?.data) {
      const sourceValue = formData.values?.source_id;
      const source = sourceValue &&
        sourceValue?.__isNew__ && {
          label: sourceCreateData?.data?.source,
          value: sourceCreateData?.data?.id,
        };

      if (sourceValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            source_id: source,
          },
        }));
      }
    }
  }, [sourceCreateProcess, sourceCreateError, sourceCreateData]);

  useEffect(() => {
    if (
      !interviewStatusCreateProcess &&
      !interviewStatusCreateError &&
      interviewStatusCreateData?.data
    ) {
      dispatch(interviewStatusFindAll());
    }
  }, [interviewStatusCreateProcess, interviewStatusCreateError, interviewStatusCreateData]);

  // Create applicant response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!createApplicantProcess && !createApplicantError && createApplicantData?.data?.id) {
        dispatch(openSnackBarLayout(createApplicantData.message, 'success', 1500));
        setIsLoading(() => false);
        push(`/applicants/view/${createApplicantData?.data?.id}`);
      } else if (createApplicantError?.message || createApplicantData?.message) {
        setIsLoading(() => false);
        dispatch(
          openSnackBarLayout(
            createApplicantError?.message || createApplicantData?.message,
            'error',
            1500,
          ),
        );
      }
    }
  }, [createApplicantProcess, createApplicantError, createApplicantData]);

  // update applicant response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!updateApplicantProcess && !updateApplicantError && updateApplicantData?.data?.id) {
        dispatch(openSnackBarLayout(updateApplicantData.message, 'success', 1500));
        setIsLoading(() => false);
        push(`/applicants/view/${updateApplicantData?.data?.id}`);
      } else if (
        createApplicantError &&
        (updateApplicantError?.message || updateApplicantData?.message)
      ) {
        setIsLoading(() => false);
        dispatch(
          openSnackBarLayout(
            updateApplicantError?.message || updateApplicantData?.message,
            'error',
            1500,
          ),
        );
      }
    }
  }, [updateApplicantProcess, updateApplicantError, updateApplicantData]);

  useEffect(() => {
    if (applicantFindData?.data && userInfo?.data.type_slug === 'client') {
      if (!applicantFindData?.data?.client_ctc) {
        setHiddenKeys((data) => [...data, 'client_ctc']);
      }
    }
  }, [applicantFindError, applicantFindData, applicantFindProcess, userInfo]);

  useEffect(() => {
    if (
      isEdit &&
      !positionFindAllByClientProcess &&
      !positionFindAllByClientError &&
      positionFindAllByClientData?.data
    ) {
      setUpdateChild(updateChild + 1);
    }
  }, [positionFindAllByClientProcess, positionFindAllByClientError, positionFindAllByClientData]);

  // form data
  useEffect(() => {
    if (isEdit) {
      if (!applicantFindProcess && !applicantFindError && applicantFindData?.data) {
        setFormData((data) => ({
          ...data,
          values: { ...applicantFindData?.data, ...data.values },
          inProgress: false,
        }));
        if (applicantFindData?.data?.client_id) {
          dispatch(positionByClient(applicantFindData?.data?.client_id));
        }
      } else if (
        !applicantFindProcess &&
        (applicantFindData?.message || applicantFindError?.message)
      ) {
        const error = applicantFindData?.message || applicantFindError?.message;

        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [applicantFindProcess, applicantFindError, applicantFindData]);

  // Skills
  useEffect(() => {
    if (!skillsFindAllProcess && !skillsFindAllError && skillsFindAllData?.data) {
      const skills =
        (skillsFindAllData &&
          skillsFindAllData?.data?.list?.map((skill) => {
            return { label: skill?.skill, value: skill?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                skill_id: commonFn.getMultiValueFromId(applicantFindData?.data?.skill_id, skills),
              }
            : data.values,
        options: { ...data.options, skills },
      }));
    }
  }, [skillsFindAllProcess, skillsFindAllError, skillsFindAllData, applicantFindData]);

  // set sources
  useEffect(() => {
    if (
      !applicantSourceFindAllProcess &&
      !applicantSourceFindAllError &&
      applicantSourceFindAllData?.data
    ) {
      const source =
        (applicantSourceFindAllData &&
          applicantSourceFindAllData?.data?.list?.map((applicantSource) => {
            return { label: applicantSource?.source, value: applicantSource?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                source_id: commonFn.getMultiValueFromId(
                  applicantFindData?.data?.source_id,
                  source,
                )[0],
              }
            : data.values,
        options: { ...data.options, source },
      }));
    }
  }, [
    applicantSourceFindAllProcess,
    applicantSourceFindAllError,
    applicantSourceFindAllData,
    applicantFindData,
  ]);

  // set clients
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData?.data) {
      const clients =
        (clientsFindAllData &&
          clientsFindAllData?.data?.list?.map((client) => {
            return { label: client?.company_name, value: client?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                client_id: commonFn.getMultiValueFromId(
                  applicantFindData?.data?.client_id,
                  clients,
                )[0],
              }
            : data.values,
        options: { ...data.options, clients },
      }));
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData, applicantFindData]);

  // set positions
  useEffect(() => {
    if (
      !positionFindAllByClientProcess &&
      !positionFindAllByClientError &&
      positionFindAllByClientData?.data
    ) {
      const positions =
        (positionFindAllByClientData &&
          positionFindAllByClientData?.data?.list?.map((position) => {
            return { label: position?.position, value: position?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && applicantFindData?.data
            ? {
                ...data.values,
                applying_position: commonFn.getMultiValueFromId(
                  applicantFindData?.data?.applying_position,
                  positions,
                )[0],
              }
            : data.values,
        options: { ...data.options, positions },
      }));
    }
  }, [positionFindAllByClientProcess, positionFindAllByClientError, positionFindAllByClientData]);

  // interview status
  useEffect(() => {
    if (!interviewStatusFindProcess && !interviewStatusFindError && interviewStatusFindData) {
      const interview_status =
        interviewStatusFindData?.data?.list?.map((status) => {
          return { label: status?.status, value: status?.id };
        }) || [];

      setFormData((data) => ({
        ...data,
        options: { ...data.options, interview_status },
      }));
    }
  }, [interviewStatusFindProcess, interviewStatusFindError, interviewStatusFindData]);

  // change event
  const changeEvent = async (data) => {
    // dropdown change event
    if (data.field && data.field.name === 'client_id') {
      setFormData((form) => ({
        ...form,
        values: { ...form.values, ...data.watch },
      }));
      dispatch(positionByClient(data?.value?.value));
    }

    // skill
    if (data?.field?.name === 'skill_id') {
      const newValue = getNewValue(data?.value);
      if (newValue?.__isNew__ && newValue?.value) {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, skill_id: data?.value },
        }));
        createNewSkill(newValue?.value);
      }
    }

    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }

      // if source id
      if (data?.field?.name === 'source_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, source_id: data?.value },
        }));

        createNewSource(data?.value?.value);
      }
    }
  };

  // create new skill
  const createNewSkill = (skill) => {
    const skillData = {
      skill: skill,
    };
    dispatch(createSkill(skillData));
  };

  const getNewValue = (value) => {
    let newValue = '';
    value &&
      value?.map((value) => {
        if (value && value?.__isNew__) {
          newValue = value;
        }
      });
    return newValue;
  };

  // Refresh skill after creating new
  useEffect(() => {
    if (!skillCreateProcess && !skillCreateError && skillCreateData?.data) {
      const skillValue = formData.values?.skill_id;

      let isNewValue = false;
      const skills =
        Array.isArray(skillValue) &&
        skillValue.map((skill) => {
          if (skill && skill?.__isNew__) {
            isNewValue = true;
            return { label: skillCreateData?.data?.skill, value: skillCreateData?.data?.id };
          }
          return skill;
        });

      if (isNewValue) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            skill_id: skills,
          },
        }));
      }
    }
  }, [skillCreateProcess, skillCreateError, skillCreateData]);

  // Form submit
  const formSubmit = (data) => {
    const request = data;

    if (!request.cv && userInfo?.data?.type_slug === 'super-admin') {
      dispatch(openSnackBarLayout('Please upload the resume', 'error', 1000));
      return false;
    }

    setIsFormSubmitted(true);
    setIsLoading(() => true);
    request.client_id = data?.client_id?.value || data?.client_id || '';
    request.skill_id = commonFn.getIdFromMultiValue(data?.skill_id);
    request.source_id = commonFn.getIdFromMultiValue(data?.source_id?.value);
    request.applying_position = data?.applying_position?.value || data?.applying_position || '';

    request.country_id = commonFn.getIdFromMultiValue(data?.country_id).value;
    request.city_id = commonFn.getIdFromMultiValue(data?.city_id).value;
    request.state_id = commonFn.getIdFromMultiValue(data?.state_id).value;

    request.interview_status = [];

    if (data?.status?.length > 0) {
      for (let i = 0; i < data.status.length; i++) {
        const interview_status =
          data?.status[i]?.value?.__isNew__ &&
          commonFn.getIdfromSingleValue(
            data?.status[i]?.value?.value,
            interviewStatusFindData?.data?.list,
            'status',
          );
        if (data.status[i] && data.status[i]?.value) {
          request.interview_status.push({
            interview_status:
              (interview_status && interview_status?.id) || data.status[i].value.value,
            comment: data.comment[i].value,
            date: data.date[i].value,
          });
        }
      }
    }

    if (isEdit) {
      dispatch(updateApplicant(match?.params?.id, request));
    } else {
      dispatch(createApplicant(request));
    }
  };

  // upload CV
  const [isUploading, setIsUploading] = useState(false);

  const fileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'cv');
      formData.append('file', file);
      setIsUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            cv: result?.data?.uploaded_url,
            cv_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('resume is not uploaded', 'error', 1000));
      }
    }
  };

  const removeCv = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        cv: '',
        cv_name: '',
      },
    }));
  };

  const UploadCV = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={fileUpload?.xs || 12}
            sm={fileUpload?.sm || 12}
            md={fileUpload?.md || 6}
            lg={fileUpload?.lg || 4}
            xl={fileUpload?.xl || 4}
          >
            <Box className={fileUpload?.formElementClass}>
              <label className="interview-label">{fileUpload.label}</label>
              {isUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isUploading && (
                <>
                  <input
                    className={fileUpload.fieldClass}
                    id={fileUpload?.id}
                    type={fileUpload?.type}
                    onChange={(e) => fileChange(e, watch)}
                  />
                  {!formData.values?.cv && (
                    <label htmlFor={fileUpload?.id} className={fileUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {fileUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.cv && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeCv(watch);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.cv}
                        target="_blank"
                        className={fileUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.cv_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  // upload client resume

  const [isClientCvUploading, setIsClientCvUploading] = useState(false);

  const clientFileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'client_cv');
      formData.append('file', file);
      setIsClientCvUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsClientCvUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            client_cv: result?.data?.uploaded_url,
            client_cv_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('client cv is not uploaded', 'error', 1000));
      }
    }
  };

  const removeClientCv = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        client_cv: '',
        client_cv_name: '',
      },
    }));
  };

  const UploadClientCV = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={clientCvUpload?.xs || 12}
            sm={clientCvUpload?.sm || 12}
            md={clientCvUpload?.md || 6}
            lg={clientCvUpload?.lg || 4}
            xl={clientCvUpload?.xl || 4}
          >
            <Box className={clientCvUpload?.formElementClass}>
              <label className="interview-label">
                {aliasObject[clientCvUpload.clientCvLabel] || clientCvUpload.label}
              </label>
              {isClientCvUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isClientCvUploading && (
                <>
                  <input
                    className={clientCvUpload.fieldClass}
                    id={clientCvUpload?.id}
                    type={clientCvUpload?.type}
                    onChange={(e) => clientFileChange(e, watch)}
                  />
                  {!formData.values?.client_cv && (
                    <label htmlFor={clientCvUpload?.id} className={clientCvUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp;{' '}
                      {aliasObject[clientCvUpload.clientCvPlaceholder] ||
                        clientCvUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.client_cv && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeClientCv(watch);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.client_cv}
                        target="_blank"
                        className={clientCvUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.client_cv_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  // interview status
  const InterviewStatus = (form) => {
    const {
      formState: { errors },
      control,
      unregister,
    } = form;
    const [count, setCount] = useState(2);

    const handleRemoveClick = (removeIndex, list) => {
      unregister(`status.${list.keyValue}.value`);
      unregister(`comment.${list.keyValue}.value`);
      unregister(`date.${list.keyValue}.value`);

      setInputList((lists) =>
        lists.filter((e, index) => {
          if (e.keyValue === list.keyValue) {
            return false;
          }
          return true;
        }),
      );
    };

    const handleAddClick = () => {
      const addField = {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        statusName: `status.${count}.value`,
        commentName: `comment.${count}.value`,
        dateName: `date.${count}.value`,
        formControlClass: 'input-form-control interview_status_row',
        containerClass: 'interview_status_row',
        fieldClass: 'text-box-style',
        textAreaClass: 'resizableTextArea',
        statusPlaceholder: 'Status',
        commentPlaceholder: 'Comment',
        commentMaxLength: 4000,
        datePlaceholder: 'Date',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box interview_status_row',
        keyValue: count,
      };
      setCount((c) => c + 1);
      setInputList((lists) => [...lists, addField]);
    };

    // list interview status based on the applicant interview status and set value
    useEffect(() => {
      if (isEdit && !applicantFindProcess && !applicantFindError && applicantFindData) {
        const applicantInterviewStatus = applicantFindData?.data?.interview_status || [];
        const comment = [undefined];
        const status = [undefined];
        const date = [undefined];

        for (let i = 0; i < applicantInterviewStatus.length; i++) {
          comment.push({ value: applicantInterviewStatus[i].comment });
          date.push({ value: applicantInterviewStatus[i].interview_date });
          status.push({
            value: {
              value: parseInt(applicantInterviewStatus[i].interview_status_id),
              label: applicantInterviewStatus[i].status,
            },
          });

          if (inputList.length !== applicantInterviewStatus.length && i !== 0) {
            const addField = {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 4,
              xl: 4,
              statusName: `status.${i + 1}.value`,
              commentName: `comment.${i + 1}.value`,
              dateName: `date.${i + 1}.value`,
              formControlClass: 'input-form-control interview_status_row',
              containerClass: 'interview_status_row',
              fieldClass: 'text-box-style',
              textAreaClass: 'resizableTextArea',
              statusPlaceholder: 'Status',
              commentPlaceholder: 'Comment',
              commentMaxLength: 4000,
              datePlaceholder: 'Date',
              variant: 'outlined',
              margin: 'dense',
              formElementClass: 'form-input-box',
              keyValue: i + 1,
            };
            setInputList((lists) => [...lists, addField]);
          }
        }

        setCount(() => applicantInterviewStatus.length + 1);

        setFormData((data) => ({
          ...data,
          values:
            match?.params?.id && applicantFindData?.data
              ? {
                  ...data.values,
                  comment: comment,
                  status: status,
                  date: date,
                }
              : data.values,
        }));
      }
    }, [applicantFindProcess, applicantFindError, applicantFindData]);
    return (
      <>
        <label className="interview-label form-input-box">Add Interview Status</label>
        {inputList.map((list, inputListIndex) => {
          return (
            <Grid container spacing={1} key={inputListIndex} className={list?.containerClass}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Box className={list?.formElementClass}>
                  <FormControl className={list?.formControlClass}>
                    <Controller
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          // create select
                          <CreatableSelect
                            inputRef={ref}
                            defaultValue={commonFn.getMultiValueFromId(
                              values?.[list?.valueId],
                              options?.[list?.optionsKey],
                            )}
                            key={list.keyValue}
                            isMulti={false}
                            isClearable={true}
                            value={value}
                            className={list?.fieldClass}
                            placeholder={list?.placeholder}
                            isDisabled={isView ? true : ''}
                            options={formData.options?.interview_status || []}
                            onChange={(value) => {
                              onChange(value);
                              onChangeValue({ value: value, field: list });
                            }}
                          />
                        );
                      }}
                      control={control}
                      key={list.keyValue}
                      name={list?.statusName}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors?.status?.[list.keyValue]?.value?.type === 'required' && (
                      <span className="error">Please select or create a status</span>
                    )}
                  </FormControl>
                </Box>
              </Grid>

              {/* interview comment */}
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <Box className={list?.formElementClass}>
                  <FormControl className={list?.formControlClass}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <TextareaAutosize
                            {...field}
                            placeholder={list?.commentPlaceholder}
                            className={`${list?.fieldClass}  ${list?.textAreaClass} `}
                            variant={list?.variant}
                            margin={list?.margin}
                            name={list?.name}
                            type={list?.type}
                            rows={list?.rows}
                            inputProps={{
                              readOnly: readOnlyKeys.indexOf(list?.name) !== -1 || list.readOnly,

                              maxLength: list?.commentMaxLength,
                            }}
                            disabled={list?.disabled}
                          />
                        );
                      }}
                      control={control}
                      key={list.keyValue}
                      name={list?.commentName}
                      rules={{
                        required: true,
                      }}
                    />
                    {errors?.comment?.[list.keyValue]?.value?.type === 'required' && (
                      <span className="error">Please enter a comment</span>
                    )}
                  </FormControl>
                </Box>
              </Grid>

              {/* Interview date */}
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <Box className={list?.formElementClass}>
                  <Controller
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          className={list?.fieldClass}
                          variant={list?.variant}
                          placeholder={list?.datePlaceholder}
                          margin={list?.margin}
                          type={'date'}
                          key={list.keyValue}
                          inputProps={{
                            readOnly: list?.readOnly,
                            maxLength: list?.max,
                          }}
                        />

                        // <DatePicker
                        //   {...field}
                        //   className={list?.fieldClass}
                        //   // selected={field.value}
                        //   margin={list?.margin}
                        //   key={list.keyValue}
                        //   placeholder={list?.datePlaceholder}
                        //   // onChange={(date) => {
                        //   //   onDateChangeValue({ date });
                        //   // }}
                        //   showTimeSelect
                        //   dateFormat="Pp"
                        // />
                      );
                    }}
                    key={list.keyValue}
                    control={control}
                    name={list?.dateName}
                  />
                  {errors?.date?.[list.keyValue]?.value?.type === 'required' && (
                    <span className="error">Please select the date</span>
                  )}
                </Box>
              </Grid>

              <Grid item>
                <Box className={list?.formElementClass}>
                  {inputList.length - 1 >= 1 && (
                    <Link
                      className={'link-button'}
                      onClick={() => handleRemoveClick(inputListIndex, list)}
                    >
                      <RemoveCircleIcon className={'interview-status-icon'} />
                    </Link>
                  )}
                  {inputList.length - 1 === inputListIndex && (
                    <Link className={'link-button'} onClick={handleAddClick}>
                      <AddCircleIcon className={'interview-status-icon'} />
                    </Link>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      {/* {((isEdit && !applicantFindProcess) || !isEdit) && ( */}

      {!isRouteChange && !formData?.inProgress && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          renderer={{ InterviewStatus, UploadCV, UploadClientCV }}
          hiddenKeys={hiddenKeys}
          aliasObject={aliasObject}
          readOnlyKeys={readOnlyKeys}
          isLoading={isLoading}
        />
      )}

      {isRouteChange && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CreateApplicant;
