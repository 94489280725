import { Types } from './types';

const initState = {
  skillsFindAllProcess: false,
  skillsFindAllError: null,
  skillsFindAllData: null,

  skillCreateProcess: false,
  skillCreateError: null,
  skillCreateData: null,
};

export const skills = (state = initState, action) => {
  switch (action.type) {
    case Types.START_SKILLS_FIND_ALL:
      return {
        ...state,
        skillsFindAllProcess: true,
        skillsFindAllError: null,
        skillsFindAllData: null,
      };
    case Types.END_SKILLS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        skillsFindAllProcess: false,
        skillsFindAllError: error,
        skillsFindAllData: success,
      };
    }

    // create skill
    case Types.START_CREATE_SKILL:
      return {
        ...state,
        skillCreateProcess: true,
        skillCreateError: null,
        skillCreateData: null,
      };
    case Types.END_CREATE_SKILL: {
      const { success, error } = action.payload;
      return {
        ...state,
        skillCreateProcess: false,
        skillCreateError: error,
        skillCreateData: success,
      };
    }
    default:
      return state;
  }
};
