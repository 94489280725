import React, { useState, useEffect } from 'react';
import { Tabs, EmployeeHead, ResponseData } from '../../../data/EmployeeData';
import { TabCustom, TableCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { useHistory } from 'react-router-dom';
import { employeeFindAll } from '../../../redux/hrms/action';
import { useDispatch, useSelector } from 'react-redux';
import { Employees } from '../../common';
import { CircularProgress } from '@material-ui/core';

export const CompanyEmployees = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [hiddenColumn, setHiddenColumn] = useState([]);

  const { employeeFindAllProcess, employeeFindAllError, employeeFindAllData } = useSelector(
    ({ employees }) => ({
      ...employees,
    }),
  );

  // Tabs
  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match?.match),
  };

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Company | Employees';
  }, []);

  // employees find all
  useEffect(() => {
    dispatch(employeeFindAll(skip, limit));
  }, [skip]);

  // set hidden keys
  useEffect(() => {
    if (employeeFindAllData) {
      setHiddenColumn(() => ['company_logo']);
    }
  }, [employeeFindAllData]);

  // extract employee data from API response
  const empResponse = employeeFindAllData?.data?.list;
  const employeeData =
    empResponse &&
    empResponse.map((emp) => {
      const text = [{ text: emp?.full_name }, { text: emp?.company_email }];
      return { ...emp, full_name: text };
    });

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
    dispatch(employeeFindAll(limit * (value - 1), limit));
  };

  // Table data
  const tableData = {
    tabs: tabs,
    module: 'company',
    count: employeeFindAllData?.data?.count,
    skip: skip,
    data: employeeData,
    head: EmployeeHead,
    pagination: {
      numberOfPages: employeeFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    hiddenKeys: hiddenColumn,
    errorMessage: employeeFindAllError?.message,
    inProgress: employeeFindAllProcess,
  };
  return (
    <>
      <Employees {...tableData} />
    </>
  );
};
