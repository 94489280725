import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Profile, ViewProfile } from '.';

export const ProfileRouter = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={'/company/profile'}
          render={(props) => <ViewProfile isView={true} {...props} />}
        />
        <Route
          exact
          path={'/company/profile/edit'}
          render={(props) => <Profile isEdit={true} {...props} />}
        />
      </Switch>
    </>
  );
};
