import { Types } from './types';

const initState = {
  documentTypesFindAllProcess: false,
  documentTypesFindAllError: null,
  documentTypesFindAllData: null,

  documentTypesCreateProcess: false,
  documentTypesCreateError: null,
  documentTypesCreateData: null
};

export const documentTypes = (state = initState, action) => {
  switch (action.type) {
    case Types.START_CREATE_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypesCreateProcess: true,
        documentTypesCreateError: null,
        documentTypesCreateData: null,
      };
    case Types.END_CREATE_DOCUMENT_TYPE: {
      const { success, error } = action.payload;
      return {
        ...state,
        documentTypesCreateProcess: false,
        documentTypesCreateError: error,
        documentTypesCreateData: success,
      };
    }

    // document types find
    case Types.START_FIND_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypesFindAllProcess: true,
        documentTypesFindAllError: null,
        documentTypesFindAllData: null,
      };
    case Types.END_FIND_DOCUMENT_TYPE: {
      const { success, error } = action.payload;
      return {
        ...state,
        documentTypesFindAllProcess: false,
        documentTypesFindAllError: error,
        documentTypesFindAllData: success,
      };
    }
    default:
      return state;
  }
};
