import React from 'react';
import { TabCustom, TableCustom } from '.';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Employees = ({
  match,
  data = [],
  head = [],
  tabs = [],
  module,
  count = 0,
  pagination = [],
  skip = 0,
  errorMessage,
  hiddenKeys = [],
  inProgress,
}) => {
  const history = useHistory();

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const employeeId = data.row[0].value;
    if (module === 'hrms') {
      if ((employeeId && data.action?.key) || (employeeId && data.action?.type === 'view')) {
        history.push(`/hrms/employees/view/${employeeId}`);
      }
      if (employeeId && data?.action?.type === 'edit') {
        history.push(`/hrms/employees/edit/${employeeId}`);
      }
    } else if (module === 'company') {
      if ((employeeId && data.action?.key) || (employeeId && data.action?.type === 'view')) {
        history.push(`/company/employees/view/${employeeId}`);
      }
      if (employeeId && data?.action?.type === 'edit') {
        history.push(`/company/employees/edit/${employeeId}`);
      }
    }
  };

  // Table data
  const tableData = {
    head,
    count,
    data,
    skip: skip,
    clickOnCell: clickOnCell,
    pagination,
    errorMessage,
    hiddenKeys,
  };

  return (
    <>
      <TabCustom {...tabs} />

      {!inProgress && (
        <Box mb={4}>
          <TableCustom {...tableData} />
        </Box>
      )}

      {inProgress && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
