import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AccountsInvoices } from '.';
import { AddOrEditInvoice, ViewInvoice } from '../../common';

export const AccountsInvoicesRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/accounts/invoices'} component={AccountsInvoices} />
        <Route
          path={'/accounts/invoices/create'}
          render={(props) => <AddOrEditInvoice {...props} />}
        />
        <Route
          path={'/accounts/invoices/view/:id'}
          render={(props) => <ViewInvoice isView={true} {...props} />}
        />
        <Route
          path={'/accounts/invoices/edit/:id'}
          render={(props) => <AddOrEditInvoice isEdit={true} {...props} />}
        />
      </Switch>
    </>
  );
};
