import React, { useEffect, useState } from 'react';
import { TableCustom, FilterCustom, TabCustom } from '../common';
import { Box, CircularProgress } from '@material-ui/core';
import { ApplicantHead, MainFilter, Tabs } from '../../data/ApplicantsData';
import '../positions/Positions.scss';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import {
  applicantsFindAll,
  applicantSourceFindAll,
  applicantDelete,
} from '../../redux/applicants/action';
import { skillsFindAll } from '../../redux/skills/action';
import { interviewStatusFindAll } from '../../redux/interview_status/action';
import { openSnackBarLayout } from '../../redux/common/action';
import './Applicant.scss';
import { clientsFindAll } from '../../redux/clients/action';
import { positionsFindAll } from '../../redux/positions/action';
import queryString from 'query-string';

// Start: Main Function
export const Applicants = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);

  const {
    applicantsFindAllError,
    applicantsFindAllData,
    applicantsFindAllProcess,
    applicantDeleteProcess,
    applicantDeleteData,
    applicantSourceFindAllData,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    positionsFindAllProcess,
    positionsFindAllError,
    positionsFindAllData,
  } = useSelector(({ applicants, clients, positions }) => ({
    ...applicants,
    ...clients,
    ...positions,
  }));

  const { skillsFindAllData, interviewStatusFindData, userInfo } = useSelector(
    ({ skills, interviewStatus, sign }) => ({
      ...skills,
      ...interviewStatus,
      ...sign,
    }),
  );

  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [filterValues, setFilterValues] = useState([]);
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [filterHiddenKeys, setFilterHiddenKeys] = useState([]);
  const [aliasObject, setAliasObject] = useState({});
  const [hiddenAction, setHiddenAction] = useState([]);

  useEffect(() => {
    document.title = 'Quark - Applicants';
  }, []);

  useEffect(() => {
    if (userInfo?.data?.type_slug === 'client') {
      setHiddenKeys(() => ['current_ctc', 'source', 'company_logo']);
      setFilterHiddenKeys(() => ['source', 'client', 'min_ctc', 'max_ctc']);

      if (
        userInfo?.data?.role_slug === 'user' &&
        userInfo?.data?.permissions_slug?.indexOf('show-client-ctc') === -1
      ) {
        setHiddenKeys((data) => [...data, 'expected_ctc']);
      }

      setAliasObject(() => ({
        expected_ctc_label: 'CTC',
      }));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(skillsFindAll());
    dispatch(applicantSourceFindAll());
    dispatch(interviewStatusFindAll());
    dispatch(clientsFindAll());
    // dispatch(applicantsFindAll(skip, limit));

    dispatch(positionsFindAll());
  }, []);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
    dispatch(applicantsFindAll(limit * (value - 1), limit, filterValues));
  };

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const applicantId = data.row[0].value;

    // open resume
    if (applicantId && data?.action?.type === 'open') {
      if (data.row[12]?.value) {
        window.open(data.row[12]?.value);
      } else {
        dispatch(openSnackBarLayout('Resume not found', 'error', 1500));
      }
    }

    // edit action
    if (applicantId && data?.action?.type === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-applicant') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/applicants/edit/${applicantId}`);
    }

    // view action
    if ((applicantId && data.action?.key) || (applicantId && data.action?.type === 'view')) {
      history.push(`/applicants/view/${applicantId}`);
    }

    // delete action
    if (applicantId && data.action.type === 'delete') {
      if (commonFn.menuHiddenKeys.indexOf('delete-applicant') !== -1) {
        commonFn.showPopup(commonFn.deleteAccessError);
        return false;
      }

      // delete confirmation dialog
      MySwal.fire({
        title: 'Are you sure?',
        text: 'You want to delete the applicant',
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        result = result.isConfirmed && dispatch(applicantDelete(applicantId));
        result &&
          Swal.fire({
            title: 'Success!',
            text: 'Applicant has been deleted',
            icon: 'success',
            iconColor: '#3085d6',
          });
      });
    }
  };

  //refresh applicant data after deleting
  useEffect(() => {
    !applicantDeleteProcess && applicantDeleteData && dispatch(applicantsFindAll(skip, limit));
  }, [applicantDeleteData]);

  const response = applicantsFindAllData?.data?.list;
  const list =
    response &&
    response.map((item) => {
      const skill =
        item.skills &&
        item.skills.split(',').map((value) => {
          return { skill: value };
        });

      const text = [{ text: item?.full_name }, { text: item?.phone }];
      const interview_status = item?.interview_status && item?.interview_status[0]?.status;

      return { ...item, skills: skill, interview_status: interview_status, full_name: text };
    });

  // hide action based on the permission
  useEffect(() => {
    if (!applicantsFindAllProcess && !applicantsFindAllError && applicantsFindAllData) {
      if (commonFn.menuHiddenKeys.indexOf('edit-applicant') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Edit']);
      }
      if (commonFn.menuHiddenKeys.indexOf('delete-applicant') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Delete']);
      }
    }
  }, [applicantsFindAllProcess, applicantsFindAllError, applicantsFindAllData]);

  // Mapping API response
  const tableData = {
    hiddenAction: hiddenAction,
    count: applicantsFindAllData?.data?.count,
    skip: skip,
    data: list,
    head: ApplicantHead,
    pagination: {
      numberOfPages: applicantsFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    clickOnCell: clickOnCell,
    errorMessage: applicantsFindAllError?.message,
  };

  // skills
  const skills =
    skillsFindAllData &&
    skillsFindAllData?.data?.list?.map((skill) => {
      return { label: skill?.skill, value: skill?.id };
    });

  // applicant source
  const source =
    applicantSourceFindAllData &&
    applicantSourceFindAllData?.data?.list?.map((source) => {
      return { label: source?.source, value: source?.id };
    });

  // clients
  const clients =
    !clientsFindAllProcess &&
    !clientsFindAllError &&
    clientsFindAllData?.data?.list?.map((client) => {
      return { label: client?.company_name, value: client?.id };
    });

  // positions
  const positions =
    !positionsFindAllProcess &&
    !positionsFindAllError &&
    positionsFindAllData?.data?.list?.map((position) => {
      return { label: position?.position, value: position?.id };
    });

  // interview status
  const interview_status =
    interviewStatusFindData &&
    interviewStatusFindData?.data?.list?.map((status) => {
      return { label: status?.status, value: status?.id };
    });

  const changeEvent = (data) => {
    const filterArray = {};

    // skill
    if (data?.field?.name === 'skill_id') {
      const skills = commonFn.getIdFromMultiValue(data?.value);
      filterArray.skill_id = skills;
    }

    // source id
    if (data?.field?.name === 'source_id') {
      const source = commonFn.getIdFromMultiValue(data?.value);
      filterArray.source_id = source;
    }

    // interview status
    if (data?.field?.name === 'interview_status_id') {
      const interviwSatus = commonFn.getIdFromMultiValue(data?.value);
      filterArray.interview_status_id = interviwSatus;
    }

    // clients
    if (data?.field?.name === 'client_id') {
      const client = commonFn.getIdFromMultiValue(data?.value);
      filterArray.client_id = client;
    }

    // position
    if (data?.field?.name === 'position_id') {
      const position = commonFn.getIdFromMultiValue(data?.value);
      filterArray.position_id = position;
    }

    setFilterValues((filterValue) => ({
      ...filterValue,
      ...filterArray,
    }));
  };

  // getting filter props from position
  useEffect(() => {
    const request = {
      position: queryString.parse(location?.search)?.position,
      client_id: queryString.parse(location?.search)?.client,
    };
    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(applicantsFindAll(0, limit, request));
  }, [location?.search]);

  // Filter Data
  const filterData = {
    filter: MainFilter,
    options: { skills, source, interview_status, clients, positions },
    values: {
      client_id: queryString.parse(location?.search)?.client,
      position: queryString.parse(location?.search)?.position,
    },
  };

  console.log('filterdata', filterData)
  // tabs
  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  // Filter form submit
  const formSubmit = (data) => {
    const request = data;
    request.skill_id = filterValues.skill_id;
    request.source_id = filterValues.source_id;
    request.interview_status_id = filterValues.interview_status_id;
    request.client_id = filterValues.client_id;

    const minCtc = request && request?.min_ctc;
    const maxCtc = request && request?.max_ctc;

    request.position = (request && request?.position) || filterValues?.position;

    if (minCtc > maxCtc) {
      return dispatch(
        openSnackBarLayout('Minimum CTC must be less than Maximum CTC', 'error', 2000),
      );
    }
    setFilterValues((filterValue) => ({ ...filterValue, ...request }));

    setSkip(() => 0);
    dispatch(applicantsFindAll(0, limit, { ...filterValues, ...request }));
  };

  return (
    <div>
      {/* Filter Card Component */}
      <TabCustom {...tabs} />

      <Box mt={2} mb={4}>
        <FilterCustom
          {...filterData}
          formSubmit={formSubmit}
          hiddenKeys={filterHiddenKeys}
          changeEvent={changeEvent}
        />
      </Box>

      {applicantsFindAllProcess && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}

      {/* Table component */}
      {!applicantsFindAllProcess && (
        <Box mb={4}>
          <TableCustom {...tableData} hiddenKeys={hiddenKeys} aliasObject={aliasObject} />
        </Box>
      )}
    </div>
  );
};
