import { AccountCircleRounded, ExitToApp } from '@material-ui/icons';
import brandLogo from '../assets/images/logo/flipkoins-logo.png';

export const NavItems = {
  profile: {
    icon: AccountCircleRounded,
    link: '',
    text: 'Profile',
    subMenu: [
      {
        icon: AccountCircleRounded,
        link: '/profile',
        text: 'Profile',
      },
      {
        icon: ExitToApp,
        link: '',
        isClick: true,
        key: 'logout',
        text: 'Sign out',
      },
    ],
  },
};

export const NavBrand = {
  brandName: '',
  url: '/positions',
  logo: {
    dark: brandLogo,
  },
};
