import { Types } from './types';

const initState = {
  isOpenSnackBar: false,
  snackBarTime: null,
  snackBarMessage: '',
  snackBarSeverity: 'success',

  isOpenSidebar: false,
  isSidebarToggled: null,
  sidebarOpenStatus: null,
};

export function common(state = initState, action) {
  switch (action.type) {
    case Types.START_SNACK_BAR: {
      const { snackBarTime, snackBarMessage, snackBarSeverity } = action.payload;
      return {
        ...state,
        isOpenSnackBar: true,
        snackBarTime: snackBarTime,
        snackBarMessage: snackBarMessage,
        snackBarSeverity: snackBarSeverity,
      };
    }

    case Types.END_SNACK_BAR:
      return {
        ...state,
        isOpenSnackBar: false,
        snackBarTime: null,
        snackBarMessage: '',
        snackBarSeverity: '',
      };

    // toggle sidebar
    case Types.START_TOGGLE_SIDEBAR: {
      return {
        ...state,
        isOpenSidebar: true,
        isSidebarToggled: true,
        sidebarOpenStatus: action.payload
      };
    }

    case Types.END_TOGGLE_SIDEBAR: {
      return {
        ...state,
        isOpenSidebar: false,
        isSidebarToggled: false,
        sidebarOpenStatus: action.payload,
      };
    }
    default:
      return state;
  }
}
