export const Types = {
  START_FIND_ALL_INVOICES: 'START_FIND_ALL_INVOICES',
  END_FIND_ALL_INVOICES: 'END_FIND_ALL_INVOICES',

  START_CREATE_INVOICE: 'START_CREATE_INVOICE',
  END_CREATE_INVOICE: 'END_CREATE_INVOICE',

  START_FIND_INVOICE: 'START_FIND_INVOICE',
  END_FIND_INVOICE: 'END_FIND_INVOICE',

  START_UPDATE_INVOICE: 'START_UPDATE_INVOICE',
  END_UPDATE_INVOICE: 'END_UPDATE_INVOICE',

  START_SEND_EMAIL_INVOICE: 'START_SEND_EMAIL_INVOICE',
  END_SEND_EMAIL_INVOICE: 'END_SEND_EMAIL_INVOICE',
};
