import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Validation } from './CommonData';

// Applicants table header
export const ApplicantHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Company',
    key: 'company_logo',
    hide: false,
    type: 'file',
    hiddenKey: 'company_logo',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  { label: 'Position', key: 'position', hide: false, type: 'text', headClass: 'w-150' },
  {
    label: 'Interview Status',
    key: 'interview_status',
    hide: false,
    headClass: 'w-200',
    type: 'text',
  },
  {
    label: 'Full Name',
    key: 'full_name',
    hide: false,
    headClass: 'w-150 ',
    bodyClass: '',
    nameKey: 'full_name',
    type: 'array',
    keys: [{ key: 'text', hide: false, arrayClass: 'name-phone-group' }],
    groupKey: 'namePhone',
  },
  {
    label: 'Experience',
    key: 'total_experience',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Skills',
    key: 'skills',
    hide: false,
    type: 'array',
    keys: [{ key: 'skill', hide: false, arrayClass: 'badge-outlined badge-warning' }],
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'CTC',
    key: 'current_ctc',
    hiddenKey: 'current_ctc',
    hide: false,
    type: 'text',
    headClass: 'w-150',
  },
  {
    label: 'Expected CTC',
    aliasLabel: 'expected_ctc_label',
    key: 'expected_ctc',
    hiddenKey: 'expected_ctc',
    hide: false,
    type: 'text',
    headClass: 'w-150',
  },
  {
    label: 'Notice Period',
    key: 'notice_period',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Date of Joining',
    key: 'joining_date',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Source',
    key: 'source',
    hide: false,
    type: 'text',
    hiddenKey: 'source',
    headClass: 'w-150',
    bodyClass: '',
  },
  // { label: 'City', key: 'city', hide: false, type: 'text', headClass: 'w-150' },
  // { label: 'State', key: 'state', hide: false, type: 'text', headClass: 'w-150' },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-200 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-warning',
        type: 'open',
        icon: GetAppIcon,
        text: 'Open CV',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: DeleteIcon,
        text: 'Delete',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
  {
    label: 'CV',
    key: 'cv',
    hide: true,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Applicants',
  button: {
    text: 'Create Applicant',
    className: 'link-button btn-sm btn-primary',
    url: '/applicants/create',
    hiddenKey: 'create-applicant',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Applicants', link: '', className: 'active' },
  ],
};

// Applicants Filter data
export const MainFilter = {
  filterClass: 'applicant-filter',
  list: [
    {
      label: 'Search',
      placeholder: 'Search',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'search',
      key: 'search',
      variant: 'outlined',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'Interview Status',
      placeholder: 'Status',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      variant: 'outlined',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      name: 'interview_status_id',
      key: 'interview_status',
      valueId: 'interview_status_id',
      optionsKey: 'interview_status',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [],
    },
    {
      label: 'Skills',
      placeholder: 'Skills',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      size: 'small',
      name: 'skill_id',
      key: 'skills',
      valueId: 'skill_id',
      optionsKey: 'skills',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [
        // { label: 'PHP', title: 'PHP', value: 'PHP' },  // for sample data
      ],
    },
    {
      label: 'Source',
      placeholder: 'Source',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'multiselect', // autocomplete
      name: 'source_id',
      key: 'source',
      valueId: 'source_id',
      optionsKey: 'source',
      hiddenKey: 'source',
      size: 'small',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [
        // { label: 'Linkedin', title: 'LinkedIn', value: 1 },  // for sample data
      ],
    },
    {
      label: 'Position',
      placeholder: 'Position',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'position',
      key: 'position',
      valueKey: 'position',
      optionsKey: 'positions',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },

    {
      label: 'CTC',
      placeholder: 'Min',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text', // autocomplete
      name: 'min_ctc',
      key: 'min_ctc',
      hiddenKey: 'min_ctc',
      size: 'small',
      xs: 12,
      sm: 6,
      md: 3,
      lg: 2,
      xl: 2,
    },
    {
      label: '',
      placeholder: 'Max',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text', // autocomplete
      name: 'max_ctc',
      key: 'max_ctc',
      size: 'small',
      hiddenKey: 'max_ctc',
      labelClass: 'filter-label-min',
      xs: 12,
      sm: 6,
      md: 3,
      lg: 2,
      xl: 2,
    },
    {
      label: 'Client',
      placeholder: 'Client',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'multiselect', // autocomplete
      name: 'client_id',
      key: 'clients',
      valueId: 'client_id',
      optionsKey: 'clients',
      hiddenKey: 'client',
      size: 'small',
      xs: 12,
      sm: 6 ,
      md: 6,
      lg: 3,
      xl: 3,
      options: [
        // { label: 'Linkedin', title: 'LinkedIn', value: 1 },  // for sample data
      ],
    },
  ],
};

// Interview status
export const InterviewStatus = [
  { label: 'Scheduled', key: 'scheduled', value: 'Scheduled' },
  { label: 'Not Scheduled', key: 'not_scheduled', value: 'Not Scheduled' },
  { label: 'Completed', key: 'completed', value: 'Completed' },
  { label: 'Await', key: 'await', value: 'Await' },
];

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Applicant',
      key: 'create',
      link: '/applicants/create',
      hiddenKey: 'create-applicant',
      className: 'tab-link',
    },
    {
      label: 'Edit Applicant',
      key: 'edit',
      link: '/applicants/edit/:id',
      path: '/applicants/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-applicant',
    },
    {
      label: 'View Applicant',
      key: 'view',
      hiddenKey: 'list-applicants',
      link: '/applicants/view/:id',
      path: '/applicants/view/:id',
      className: 'tab-link',
    },
    {
      label: 'Applicants',
      key: 'applicants',
      link: '/applicants',
      className: 'tab-link',
      hiddenKey: 'list-applicants',
    },
  ],
};
