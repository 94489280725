import { baseApiServer } from './environment';

export const apiList = {
  login: `${baseApiServer('USERS')}/login`,
  forgotPassword: `${baseApiServer('USERS')}/forgot-password`,
  resetPassword: `${baseApiServer('USERS')}/reset-password`,
  logout: `${baseApiServer('USERS')}/logout`,
  verify: `${baseApiServer('USERS')}/verify`,
  isAuth: `${baseApiServer('USERS')}/is-auth`,
  users: `${baseApiServer('USERS')}`,

  clients: `${baseApiServer('CLIENTS')}`,

  userTypes: `${baseApiServer('USERTYPES')}`,
  userRoles: `${baseApiServer('USERROLES')}`,
  permissions: `${baseApiServer('PERMISSIONS')}`,
  positionTypes: `${baseApiServer('POSITION_TYPES')}`,
  skills: `${baseApiServer('SKILLS')}`,
  locations: `${baseApiServer('LOCATIONS')}`,
  positionStatus: `${baseApiServer('POSITION_STATUS')}`,
  timezones: `${baseApiServer('TIMEZONES')}`,

  positions: `${baseApiServer('POSITIONS')}`,

  applicants: `${baseApiServer('APPLICANTS')}`,

  positionsByClient: `${baseApiServer('POSITIONS_BY_CLIENT')}`,
  applicantSources: `${baseApiServer('APPLICANT_SOURCES')}`,

  userProfile: `${baseApiServer('USER_PROFILE')}`,
  userPassword: `${baseApiServer('USER_PASSWORD')}`,

  positionsFilter: `${baseApiServer('POSITIONS_FILTER')}`,
  applicantsFilter: `${baseApiServer('APPLICANTS_FILTER')}`,

  fileUpload: `${baseApiServer('FILE_UPLOAD')}`,
  cities: `${baseApiServer('CITIES')}`,
  states: `${baseApiServer('STATES')}`,
  countries: `${baseApiServer('COUNTRIES')}`,
  interview_status: `${baseApiServer('INTERVIEW_STATUS')}`,

  employees: `${baseApiServer('EMPLOYEES')}`,

  documentTypes: `${baseApiServer('DOCUMENT_TYPES')}`,

  clientProfile: `${baseApiServer('CLIENT_PROFILE')}`,
  invoices: `${baseApiServer('INVOICES')}`,
  invoiceStatus: `${baseApiServer('INVOICE_STATUS')}`,
  holidays: `${baseApiServer('HOLIDAYS')}`,
  holidaysNote: `${baseApiServer('HOLIDAYS_NOTE')}`,
  vendorBank: `${baseApiServer('VENDOR_BANK')}`,
  sendInvoice: `${baseApiServer('SEND_INVOICE')}`,
};
