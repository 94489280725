import React, { useEffect, useState } from 'react';
import { FormInputsData, FileUpload, expType } from '../../data/CreatePositionData';
import { Tabs } from '../../data/PositionsData';
import { FormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { clientFind, clientsFindAll } from '../../redux/clients/action';
import { positionTypesFindAll } from '../../redux/master/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPosition, positionFind, updatePosition } from '../../redux/positions/action';
import { skillsFindAll } from '../../redux/skills/action';
import { locationsFindAll } from '../../redux/locations/action';
import { positionStatusFindAll } from '../../redux/position_status/action';
import { openSnackBarLayout } from '../../redux/common/action';
import { CircularProgress, Grid, Box } from '@material-ui/core';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCountry,
  createState,
  createCity,
} from '../../redux/cities/action';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createSkill } from '../../redux/skills/action';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const CreatePosition = ({ isEdit, isView, match }) => {
  const MySwal = withReactContent(Swal);

  // position
  const {
    createPositionProcess,
    createPositionError,
    createPositionData,
    positionFindError,
    positionFindData,
    positionFindProcess,
    updatePositionProcess,
    updatePositionError,
    updatePositionData,
    skillsFindAllProcess,
    skillsFindAllError,
    skillsFindAllData,
    statusFindAllProcess,
    statusFindAllError,
    statusFindAllData,
    positionTypesFindAllProcess,
    positionTypesFindAllError,
    positionTypesFindAllData,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    skillCreateProcess,
    skillCreateError,
    skillCreateData,
  } = useSelector(
    ({ positions, skills, locations, positionStatus, positionTypes, clients, citiesNew }) => ({
      ...positions,
      ...skills,
      ...locations,
      ...positionStatus,
      ...positionTypes,
      ...clients,
      ...citiesNew,
    }),
  );

  const [limit, skip] = '';
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [formData, setFormData] = useState({});
  const [fileUpload, setFileUpload] = useState(FileUpload);
  const [clientLogo, setClientLogo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // mount
  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
    }));
    setIsRouteChange(true);
    dispatch(clientsFindAll(skip, limit));
    dispatch(positionTypesFindAll());
    dispatch(skillsFindAll());
    dispatch(locationsFindAll());
    dispatch(positionStatusFindAll());
    match?.params?.id && dispatch(positionFind(match?.params?.id));
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
  }, [match?.params?.id]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      options: { ...data.options, min_exp_type: expType, max_exp_type: expType },
      inProgress: isEdit ? true : false,
    }));
  }, []);

  // position
  useEffect(() => {
    if (!positionFindProcess && !positionFindError && positionFindData?.data) {
      const min_exp_type_id = {
        label: positionFindData?.data?.min_experience_type,
        value: positionFindData?.data?.min_experience_type === 'Month(s)' ? 1 : 2,
      };

      const max_exp_type_id = {
        label: positionFindData?.data?.max_experience_type,
        value: positionFindData?.data?.max_experience_type === 'Month(s)' ? 1 : 2,
      };

      const minExperience = positionFindData && positionFindData?.data?.min_experience.split(' ');
      const maxExperience = positionFindData && positionFindData?.data?.max_experience.split(' ');

      setFormData((data) => ({
        ...data,
        values: match?.params?.id
          ? {
              ...positionFindData?.data,
              min_exp_type_id,
              max_exp_type_id,
              min_experience: minExperience[0],
              max_experience: maxExperience[0],
            }
          : data.values,
        inProgress: false,
      }));
    } else if (!positionFindProcess && (positionFindData?.message || positionFindError?.message)) {
      const error = positionFindData?.message || positionFindError?.message;
      setFormData((data) => ({ ...data, error, inProgress: false }));
    }
  }, [positionFindProcess, positionFindError, positionFindData]);

  // company name
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData?.data) {
      const clients =
        (clientsFindAllData &&
          clientsFindAllData?.data?.list?.map((client) => {
            return { label: client?.company_name, value: client?.id, logo: client?.company_logo };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                company_id: commonFn.getMultiValueFromId(
                  positionFindData?.data?.company_id,
                  clients,
                )[0],
              }
            : data.values,
        options: { ...data.options, clients },
      }));
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData, positionFindData]);

  // Position types
  useEffect(() => {
    if (
      !positionTypesFindAllProcess &&
      !positionTypesFindAllError &&
      positionTypesFindAllData?.data
    ) {
      const positionType =
        (positionTypesFindAllData &&
          positionTypesFindAllData?.data?.list?.map((type) => {
            return { label: type?.position_type, value: type?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                position_type_id: commonFn.getMultiValueFromId(
                  positionFindData?.data?.position_type_id,
                  positionType,
                ),
              }
            : data.values,
        options: { ...data.options, positionType },
      }));
    }
  }, [
    positionTypesFindAllProcess,
    positionTypesFindAllError,
    positionTypesFindAllData,
    positionFindData,
  ]);

  // Skills
  useEffect(() => {
    if (!skillsFindAllProcess && !skillsFindAllError && skillsFindAllData?.data) {
      const skills =
        (skillsFindAllData &&
          skillsFindAllData?.data?.list?.map((skill) => {
            return { label: skill?.skill, value: skill?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                skill_id: commonFn.getMultiValueFromId(positionFindData?.data?.skill_id, skills),
              }
            : data.values,
        options: { ...data.options, skills },
      }));
    }
  }, [skillsFindAllProcess, skillsFindAllError, skillsFindAllData, positionFindData]);

  // City
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getId = formData.values?.city_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.city_id?.value, city).value
        : positionFindData?.data?.city_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                city_id: commonFn.getMultiValueFromId(getId, city)[0],
              }
            : data.values,
        options: { ...data.options, city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, positionFindData]);

  // State
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getId = formData.values?.state_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.state_id?.value, state).value
        : positionFindData?.data?.state_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                state_id: commonFn.getMultiValueFromId(getId, state)[0],
              }
            : data.values,
        options: { ...data.options, state },
      }));
    }
  }, [stateFindAllProcess, statusFindAllError, stateFindAllData, positionFindData]);

  // Country
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = formData.values?.country_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.country_id?.value, country).value
        : positionFindData?.data?.country_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                country_id: commonFn.getMultiValueFromId(getId, country)[0],
              }
            : data.values,
        options: { ...data.options, country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, positionFindData]);

  // Status
  useEffect(() => {
    if (!statusFindAllProcess && !statusFindAllError && statusFindAllData?.data) {
      const status =
        (statusFindAllData &&
          statusFindAllData?.data?.list?.map((status) => {
            return { label: status?.status, value: status?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values: {
          ...data.values,
        },
        values:
          match?.params?.id && positionFindData?.data
            ? {
                ...data.values,
                status_id: commonFn.getMultiValueFromId(
                  positionFindData?.data?.status_id,
                  status,
                )[0],
              }
            : data.values,
        options: { ...data.options, status },
      }));
    }
  }, [statusFindAllProcess, statusFindAllError, statusFindAllData, positionFindData]);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh country after creating
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const countryValue = formData.values?.country_id;

      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            country_id: country,
          },
        }));
      }
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            state_id: state,
          },
        }));
      }
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  // Refresh city after creating new
  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const cityValue = formData.values?.city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            city_id: city,
          },
        }));
      }
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // create new skill
  const createNewSkill = (skill) => {
    const skillData = {
      skill: skill,
    };
    dispatch(createSkill(skillData));
  };

  // Refresh skill after creating new
  useEffect(() => {
    if (!skillCreateProcess && !skillCreateError && skillCreateData?.data) {
      const skillValue = formData.values?.skill_id;

      let isNewValue = false;
      const skills =
        Array.isArray(skillValue) &&
        skillValue.map((skill) => {
          if (skill && skill?.__isNew__) {
            isNewValue = true;
            return { label: skillCreateData?.data?.skill, value: skillCreateData?.data?.id };
          }
          return skill;
        });

      if (isNewValue) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            skill_id: skills,
          },
        }));
      }
    }
  }, [skillCreateProcess, skillCreateError, skillCreateData]);

  const getNewValue = (value) => {
    let newValue = '';
    value &&
      value?.map((value) => {
        if (value && value?.__isNew__) {
          newValue = value;
        }
      });
    return newValue;
  };

  // onchange event
  const changeEvent = async (data) => {
    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }
    }

    // skill
    if (data?.field?.name === 'skill_id') {
      const newValue = getNewValue(data?.value);
      if (newValue?.__isNew__ && newValue?.value) {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, skill_id: data?.value },
        }));
        createNewSkill(newValue?.value);
      }
    }
  };

  // upload jd
  const UploadJD = (form, watch) => {
    const [isUploading, setIsUploading] = useState(false);

    const fileChange = async (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target?.files[0];
        var formData = new FormData();
        formData.append('folderName', 'jd');
        formData.append('file', file);
        setIsUploading(() => true);

        const response = await axios.post(apiList.fileUpload, formData, {
          headers: {},
          withCredentials: true,
        });
        const result = response?.data || {};

        setIsUploading(() => false);
        if (result?.data) {
          setFormData((data) => ({
            ...data,
            values: {
              ...data.values,
              ...watch,
              job_description: result?.data?.uploaded_url,
              job_description_original: result?.data?.original_name,
            },
          }));
        } else {
          dispatch(openSnackBarLayout('JD is not uploaded', 'error', 1000));
        }
      }
    };

    const removeJd = () => {
      setFormData((data) => ({
        ...data,
        values: {
          ...data.values,
          ...watch,
          job_description: '',
          job_description_original: '',
        },
      }));
    };

    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={fileUpload?.xs || 12}
            sm={fileUpload?.sm || 12}
            md={fileUpload?.md || 6}
            lg={fileUpload?.lg || 4}
            xl={fileUpload?.xl || 4}
          >
            <Box className={fileUpload?.formElementClass}>
              <label className="interview-label">{fileUpload.label}</label>
              {isUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isUploading && (
                <>
                  <input
                    accept={fileUpload?.accept}
                    className={fileUpload.fieldClass}
                    id={fileUpload?.id}
                    type={fileUpload?.type}
                    onChange={(e) => fileChange(e)}
                  />
                  {!formData.values?.job_description && (
                    <label htmlFor={fileUpload?.id} className={fileUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {fileUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.job_description && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeJd();
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.job_description}
                        target="_blank"
                        className={fileUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.job_description_original}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  // update position success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!updatePositionProcess && !updatePositionError && updatePositionData?.data?.id) {
        dispatch(openSnackBarLayout(updatePositionData?.message, 'success', 1500));
        setIsLoading(() => false);
        push(`/positions/view/${updatePositionData?.data?.id}`);
      } else if (createPositionError?.message || updatePositionData?.message) {
        setIsLoading(() => false);
        dispatch(
          openSnackBarLayout(
            createPositionError?.message || updatePositionData?.message,
            'error',
            1500,
          ),
        );
      }
    }
  }, [updatePositionProcess, updatePositionError, updatePositionData]);

  // create position response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!createPositionProcess && !createPositionError && createPositionData?.data?.id) {
        dispatch(openSnackBarLayout(createPositionData?.message, 'success', 1500));
        setIsLoading(() => false);
        push(`/positions/view/${createPositionData?.data?.id}`);
      } else if (createPositionError?.message || createPositionData?.message) {
        setIsLoading(() => false);
        dispatch(
          openSnackBarLayout(
            createPositionError?.message || createPositionData?.message,
            'error',
            1500,
          ),
        );
      }
    }
  }, [createPositionProcess, createPositionError, createPositionData]);

  // form submit action
  const formSubmit = (data) => {
    setIsFormSubmitted(true);
    setIsLoading(true);
    const request = data;
    request.company_id = data?.company_id?.value || data?.company_id || '';
    request.position_type_id = commonFn.getIdFromMultiValue(data?.position_type_id);
    request.skill_id = commonFn.getIdFromMultiValue(data?.skill_id);
    request.status_id = data?.status_id?.value || data?.status_id || '';
    request.min_experience_type = commonFn.getIdFromMultiValue(data?.min_exp_type_id?.label);
    request.max_experience_type = commonFn.getIdFromMultiValue(data?.max_exp_type_id?.label);
    request.job_description = formData?.values?.job_description || '';
    request.job_description_original = formData?.values?.job_description_original || '';

    request.country_id = commonFn.getIdFromMultiValue(data?.country_id).value;
    request.city_id = commonFn.getIdFromMultiValue(data?.city_id).value;
    request.state_id = commonFn.getIdFromMultiValue(data?.state_id).value;

    if (isEdit) {
      dispatch(updatePosition(match?.params?.id, request));
    } else {
      dispatch(createPosition(request));
    }
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && !formData?.inProgress && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          renderer={{ UploadJD }}
          logo={clientLogo}
          isLoading={isLoading}
        />
      )}
      {!positionFindError && positionFindProcess && isRouteChange && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default CreatePosition;
