import { Types } from './types';

const initState = {
  userTypesFindProcess: false,
  userTypesFindError: null,
  userTypesFindData: null,

  userRolesFindProcess: false,
  userRolesFindError: null,
  userRolesFindData: null
};

export const userTypesNew = (state = initState, action) => {
  switch (action.type) {
    case Types.START_USER_TYPES_FIND:
      return {
        ...state,
        userTypesFindProcess: true,
        userTypesFindError: null,
        userTypesFindData: null,
      };
    case Types.END_USER_TYPES_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        userTypesFindProcess: false,
        userTypesFindError: error,
        userTypesFindData: success,
      };
    }

    case Types.START_USER_ROLES_FIND:
      return {
        ...state,
        userRolesFindProcess: true,
        userRolesFindError: null,
        userRolesFindData: null,
      };
    case Types.END_USER_ROLES_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        userRolesFindProcess: false,
        userRolesFindError: error,
        userRolesFindData: success,
      };
    }
    default:
      return state;
  }
};