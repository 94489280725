import EditIcon from '@material-ui/icons/Edit';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    profile_image: 'JD',
    full_name: 'John Doe',
    email: 'johndoe@test.com',
    employee_id: '38748',
    designation: 'Developer',
    phone: '+91 9999999999',
  },
  {
    id: 2,
    profile_image: 'JK',
    full_name: 'Jack Ketlin',
    email: 'jackketlin@test.com',
    employee_id: '38749',
    designation: 'Developer',
    phone: '+91 9999999999',
  },
];

// Applicants table header
export const EmployeeHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'profile_image',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'Client',
    key: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
    hiddenKey: 'company_logo',
  },
  {
    label: 'Name',
    key: 'full_name',
    hide: false,
    headClass: 'w-200',
    type: 'array',
    keys: [{ key: 'text', hide: false, arrayClass: 'name-phone-group' }],
  },
  { label: 'Employee ID', key: 'employee_id', hide: false, type: 'text', headClass: 'w-150' },
  
  { label: 'Designation', key: 'designation', hide: false, type: 'text', headClass: 'w-200' },
  {
    label: 'Phone no.',
    key: 'phone',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Employees',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: '' },
    { text: 'Employees', link: '', className: 'active' },
  ],
};

// hrms page header
export const HrmsPageHeader = {
  className: 'page-header',
  page_title: 'Employees',
  button: {
    text: 'Create Employee',
    className: 'link-button btn-sm btn-primary',
    url: '/hrms/employees/create',
    hiddenKey: 'create-client',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'HRMS', link: '/hrms/employees', className: '' },
    { text: 'Employees', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Employees',
      key: 'employees',
      link: '/company/employees',
      path: '/company/employees',
      className: 'tab-link',
      hiddenKey: 'list-employees',
    },
    {
      label: 'View Employee',
      key: 'view',
      link: '/company/employees/view/:id',
      path: '/company/employees/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-employees',
    },
    {
      label: 'Edit Employee',
      key: 'edit',
      link: '/company/employees/edit/:id',
      path: '/company/employees/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-employees',
    },
  ],
};

// HRMS tab
export const HrmsTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Employee',
      key: 'create',
      link: '/hrms/employees/create',
      path: '/hrms/employees/create',
      className: 'tab-link',
      hiddenKey: 'create-employee',
    },
    {
      label: 'Edit Employee',
      key: 'edit',
      link: '/hrms/employees/edit/:id',
      path: '/hrms/employees/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-employee',
    },
    {
      label: 'View Employee',
      key: 'view',
      link: '/hrms/employees/view/:id',
      path: '/hrms/employees/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-employees',
    },
    {
      label: 'Employees',
      key: 'employees',
      link: '/hrms/employees',
      className: 'tab-link',
      hiddenKey: 'list-employees',
    },
  ],
};
