import { Types } from './types';

const initState = {
  cityFindAllProcess: false,
  cityFindAllError: null,
  cityFindAllData: null,

  stateFindAllProcess: false,
  stateFindAllError: null,
  stateFindAllData: null,

  countryFindAllProcess: false,
  countryFindAllError: null,
  countryFindAllData: null,

  countryCreateProcess: false,
  countryCreateError: null,
  countryCreateData: null,

  stateCreateProcess: false,
  stateCreateError: null,
  stateCreateData: null,

  cityCreateProcess: false,
  cityCreateError: null,
  cityCreateData: null,
};

export const citiesNew = (state = initState, action) => {
  switch (action.type) {
    // city
    case Types.START_CITY_FIND_ALL:
      return {
        ...state,
        cityFindAllProcess: true,
        cityFindAllError: null,
        cityFindAllData: null,
      };
    case Types.END_CITY_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        cityFindAllProcess: false,
        cityFindAllError: error,
        cityFindAllData: success,
      };
    }
     // state
     case Types.START_STATE_FIND_ALL:
      return {
        ...state,
        stateFindAllProcess: true,
        stateFindAllError: null,
        stateFindAllData: null,
      };
    case Types.END_STATE_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        stateFindAllProcess: false,
        stateFindAllError: error,
        stateFindAllData: success,
      };
    }
     // country
     case Types.START_COUNTRY_FIND_ALL:
      return {
        ...state,
        countryFindAllProcess: true,
        countryFindAllError: null,
        countryFindAllData: null,
      };
    case Types.END_COUNTRY_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        countryFindAllProcess: false,
        countryFindAllError: error,
        countryFindAllData: success,
      };
    }

    // country create
    case Types.START_CREATE_COUNTRY:
      return {
        ...state,
        countryCreateProcess: true,
        countryCreateError: null,
        countryCreateData: null,
      };
    case Types.END_CREATE_COUNTRY: {
      const { success, error } = action.payload;
      return {
        ...state,
        countryCreateProcess: false,
        countryCreateError: error,
        countryCreateData: success,
      };
    }

     // state create
     case Types.START_CREATE_STATE:
      return {
        ...state,
        stateCreateProcess: true,
        stateCreateError: null,
        stateCreateData: null,
      };
    case Types.END_CREATE_STATE: {
      const { success, error } = action.payload;
      return {
        ...state,
        stateCreateProcess: false,
        stateCreateError: error,
        stateCreateData: success,
      };
    }

    // city create
    case Types.START_CREATE_CITY:
      return {
        ...state,
        cityCreateProcess: true,
        cityCreateError: null,
        cityCreateData: null,
      };
    case Types.END_CREATE_CITY: {
      const { success, error } = action.payload;
      return {
        ...state,
        cityCreateProcess: false,
        cityCreateError: error,
        cityCreateData: success,
      };
    }
    default:
      return state;
  }
};
