import { Edit, Info, Email, Print } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    avatar: 'JD',
    name: 'John Doe',
    carry_forward: 2,
    comp_of_obtained: 4,
    cl_taken: 2,
    sl_taken: 1,
    comp_of_taken: 2,
    cl_pending: 2,
    carry_forward_for_next_year: 4,
    lop_deducted: 0,
  },
];

// holidays table header
export const AttendanceHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'avatar',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'Name',
    key: 'name',
    hide: false,
    headClass: 'w-200',
    type: 'text',
    bodyClass: '',
  },
  {
    label: 'Carry Forward From Previous Year',
    key: 'carry_forward',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'Comp. of Obtained',
    key: 'comp_of_obtained',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'CL Taken',
    key: 'cl_taken',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'SL Taken',
    key: 'sl_taken',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'Comp. of Taken',
    key: 'comp_of_taken',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'CL Pending For This Year',
    key: 'cl_pending',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'Carry Forward For Next Year',
    key: 'carry_forward_for_next_year',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
  {
    label: 'LOP Deducted',
    key: 'lop_deducted',
    hide: false,
    type: 'text',
    headClass: 'w-132',
    bodyClass: '',
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Attendance Register',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: 'active' },
    { text: 'Attendance', link: '', className: 'active' },
  ],
};