import { combineReducers } from 'redux';
import { common } from './common/reducer';
import { sign } from './sign/reducer';
import { users } from './users/reducer';
import { clients } from './clients/reducer';
import { permissions, positionTypes, timezones } from './master/reducer';
import { positions } from './positions/reducer';
import { skills } from './skills/reducer';
import { locations } from './locations/reducer';
import { positionStatus } from './position_status/reducer';
import { applicants } from './applicants/reducer';
import { userProfile } from './profile/reducer';
import { positionsFilter } from './filter/reducer';
import { userTypesNew } from './user_types/reducer';
import { citiesNew } from './cities/reducer';
import { interviewStatus } from './interview_status/reducer';
import { employees } from './hrms/reducer';
import { documentTypes } from './document_type/reducer';
import { invoices } from './invoices/reducer';
import { invoiceStatus } from './master/reducer';
import { holidays } from './holidays/reducer';
import { vendorBankDetail } from './bankDetails/reducer';

export const rootReducer = combineReducers({
  sign,
  users,
  clients,
  userTypesNew,
  permissions,
  positionTypes,
  common,
  positions,
  skills,
  locations,
  positionStatus,
  applicants,
  userProfile,
  positionsFilter,
  timezones,
  citiesNew,
  interviewStatus,
  employees,
  documentTypes,
  invoices,
  invoiceStatus,
  holidays,
  vendorBankDetail,
});
