import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormCustom, TabCustom } from '../../common';
import { FormInputsData, ProfileTabs } from '../../../data/CompanyProfileData';
import { commonFn } from '../../../util/commonFn';
import { clientProfileFind, updateClientProfile } from '../../../redux/clients/action';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCountry,
  createState,
  createCity,
} from '../../../redux/cities/action';
import { timezonesFindAll } from '../../../redux/master/action';
import axios from 'axios';
import { apiList } from '../../../util/apiList';
import { openSnackBarLayout } from '../../../redux/common/action';

export const Profile = (isView, isEdit, match, docErrorMessage = 'No document found') => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    clientProfileFindProcess,
    clientProfileFindError,
    clientProfileFindData,
    updateClientProfileProcess,
    updateClientProfileError,
    updateClientProfileData,
    userInfo,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    timezonesFindAllProcess,
    timezonesFindAllError,
    timezonesFindAllData,
  } = useSelector(({ clients, sign, citiesNew, timezones }) => ({
    ...clients,
    ...sign,
    ...timezones,
    ...citiesNew,
  }));

  useEffect(() => {
    userInfo && userInfo?.data && dispatch(clientProfileFind(userInfo?.data?.company_id));
  }, [userInfo]);

  const tabs = {
    ...ProfileTabs,
    // list: commonFn.updateLink(ProfileTabs.list, match),
  };

  // form data
  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      values: {},
      error: '',
      inProgress: isEdit ? clientProfileFindProcess : false,
      options: {},
    }));
    setIsRouteChange(true);
    userInfo?.data?.company_id && dispatch(clientProfileFind(userInfo?.data?.company_id));
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());
    dispatch(timezonesFindAll());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
  }, [userInfo?.data?.company_id]);

  // Mapping Client Profile data
  useEffect(() => {
    if (isEdit) {
      if (!clientProfileFindProcess && !clientProfileFindError && clientProfileFindData?.data) {
        setFormData((data) => ({
          ...data,
          values: clientProfileFindData?.data,
          inProgress: false,
        }));
      } else if (
        !clientProfileFindProcess &&
        clientProfileFindError &&
        !clientProfileFindData?.data
      ) {
        const error = clientProfileFindData?.message || clientProfileFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [clientProfileFindProcess, clientProfileFindError, clientProfileFindData]);
  // End client data

  // Mapping Country Data
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = clientProfileFindData?.data?.country_id;
      const getBillingId = clientProfileFindData?.data?.billing_country_id;

      setFormData((data) => ({
        ...data,
        values: clientProfileFindData?.data
          ? {
              ...data.values,
              country_id:
                typeof data.values?.country_id === 'object'
                  ? data.values?.country_id
                  : commonFn.getMultiValueFromId(getId, country)[0],
              billing_country_id:
                typeof data.values?.billing_country_id === 'object'
                  ? data.values?.billing_country_id
                  : commonFn.getMultiValueFromId(getBillingId, country)[0],
            }
          : data.values,
        options: { ...data.options, country, billing_country: country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, clientProfileFindData]);
  // end mapping country data

  // Mapping State Data
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getBillingId = clientProfileFindData?.data?.billing_state_id;
      const getId = clientProfileFindData?.data?.state_id;

      setFormData((data) => ({
        ...data,
        values: clientProfileFindData?.data
          ? {
              ...data.values,
              state_id:
                typeof data.values?.state_id === 'object'
                  ? data.values?.state_id
                  : commonFn.getMultiValueFromId(getId, state)[0],
              billing_state_id:
                typeof data.values?.billing_state_id === 'object'
                  ? data.values?.billing_state_id
                  : commonFn.getMultiValueFromId(getBillingId, state)[0],
            }
          : data.values,
        options: { ...data.options, state, billing_state: state },
      }));
    }
  }, [stateFindAllProcess, stateFindAllError, stateFindAllData, clientProfileFindData]);
  // End mapping state data

  // Mapping City Data
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getBillingId = clientProfileFindData?.data?.billing_city_id;
      const getId = clientProfileFindData?.data?.city_id;

      setFormData((data) => ({
        ...data,
        values: clientProfileFindData?.data
          ? {
              ...data.values,
              city_id:
                typeof data.values?.city_id === 'object'
                  ? data.values?.city_id
                  : commonFn.getMultiValueFromId(getId, city)[0],
              billing_city_id:
                typeof data.values?.billing_city_id === 'object'
                  ? data.values?.billing_city_id
                  : commonFn.getMultiValueFromId(getBillingId, city)[0],
            }
          : data.values,
        options: { ...data.options, city, billing_city: city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, clientProfileFindData]);
  // End mapping city data

  // set timezones
  useEffect(() => {
    if (!timezonesFindAllProcess && !timezonesFindAllError && timezonesFindAllData?.data) {
      const timezones =
        (timezonesFindAllData &&
          timezonesFindAllData?.data?.list?.map((timezone) => {
            return { label: timezone?.timezone_display_name, value: timezone?.id };
          })) ||
        [];

      setFormData((data) => ({
        ...data,
        values: clientProfileFindData?.data
          ? {
              ...data.values,
              timezone_id: commonFn.getMultiValueFromId(
                clientProfileFindData?.data?.timezone_id,
                timezones,
              )[0],
            }
          : data.values,
        options: { ...data.options, timezones },
      }));
    }
  }, [timezonesFindAllProcess, timezonesFindAllError, timezonesFindAllData, clientProfileFindData]);

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh country/billing country after creating new
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const billingCountryValue = formData.values?.billing_country_id;

      const billing_country = billingCountryValue &&
        billingCountryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (billingCountryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_country_id: billing_country,
          },
        }));
      }
      const countryValue = formData.values?.country_id;
      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            country_id: country,
          },
        }));
      }
      dispatch(countriesFindAll());
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state/billing state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            state_id: state,
          },
        }));
      }
      const billingStateValue = formData.values?.billing_state_id;
      const billingState = billingStateValue &&
        billingStateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (billingStateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_state_id: billingState,
          },
        }));
      }
      dispatch(statesFindAll());
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  // Refresh city/billing city after creating new
  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const billingCityValue = formData.values?.billing_city_id;
      const billingCity = billingCityValue &&
        billingCityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (billingCityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_city_id: billingCity,
          },
        }));
      }
      const cityValue = formData.values?.city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            city_id: city,
          },
        }));
      }
      dispatch(citiesFindAll());
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // change event
  const changeEvent = async (data) => {
    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }

      // if billing country id
      if (data?.field?.name === 'billing_country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_country_id: data?.value },
        }));

        createNewCountry(data?.value?.value);
      }

      // if billing state id
      if (data?.field?.name === 'billing_state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_state_id: data?.value },
        }));

        createNewState(data?.value?.value);
      }

      // if billing city id
      if (data?.field?.name === 'billing_city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_city_id: data?.value },
        }));

        createNewCity(data?.value?.value);
      }
    }

    // company logo upload
    if (
      data.field?.name === 'company_logo' &&
      data?.value?.target?.files &&
      data.value.target.files?.length > 0
    ) {
      const file = data?.value?.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'company');
      formData.append('file', file);
      setFormData((data) => ({ ...data, inProgress: true }));
      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });

      const result = response?.data || {};
      if (result?.data) {
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            ...data.watch,
            company_logo: result?.data?.uploaded_url,
            original_name: result?.data?.original_name,
          },
          inProgress: false,
        }));
      } else {
        dispatch(openSnackBarLayout('Company image is not uploaded', 'error', 1000));
      }
    }

    // Remove upload company logo
    if (data.field?.name === 'remove') {
      setFormData((form) => ({
        ...form,
        values: { ...form.values, ...data.watch, company_logo: '' },
      }));
    }
  };

  // update client profile success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!updateClientProfileProcess && !updateClientProfileError && updateClientProfileData) {
        dispatch(openSnackBarLayout(updateClientProfileData.message, 'success', 1000));
        setIsLoading(() => false);
        history.push(`/company/profile`);
      } else if (updateClientProfileError) {
        dispatch(openSnackBarLayout(updateClientProfileData.message, 'error', 1000));
      }
    }
  }, [updateClientProfileProcess, updateClientProfileError, updateClientProfileData]);

  // form submit
  const formSubmit = (data) => {
    setIsFormSubmitted(() => true);
    setIsLoading(() => true);
    const request = data;

    request.company_logo = formData?.values?.company_logo || '';
    request.timezone_id = data?.timezone_id?.value || data?.timezone_id || '';

    request.country_id = commonFn.getIdFromMultiValue(data?.country_id).value;
    request.city_id = commonFn.getIdFromMultiValue(data?.city_id).value;
    request.state_id = commonFn.getIdFromMultiValue(data?.state_id).value;

    request.billing_country_id = commonFn.getIdFromMultiValue(data?.billing_country_id).value;
    request.billing_city_id = commonFn.getIdFromMultiValue(data?.billing_city_id).value;
    request.billing_state_id = commonFn.getIdFromMultiValue(data?.billing_state_id).value;

    if (isEdit) {
      // update api
      dispatch(updateClientProfile(userInfo?.data?.company_id, data));
    }
  };

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        <h4 className="interview-label document-view-label form-input-box">
          <strong> Document(s)</strong>
        </h4>

        {(clientProfileFindData?.data?.documents.length > 0 &&
          clientProfileFindData?.data?.documents?.map((list, inputListIndex) => (
            <Grid container spacing={1} key={inputListIndex}>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  <label className="interview-label">{list.document_type}</label>
                </Box>
              </Grid>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  {list?.document && (
                    <a href={list?.document} target="_blank">
                      {list.document_name}
                    </a>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))) || (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box pl={3}>
                <p className="error">{docErrorMessage}</p>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          isLoading={isLoading}
          changeEvent={changeEvent}
          renderer={{ UploadDocuments }}
        />
      )}

      {isRouteChange && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
