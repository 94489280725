import React, { useState, useEffect } from 'react';
import { TableCustom, TabCustom, DialogBox } from '.';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';
import { SendInvoiceFormData } from '../../data/AccountsData';
import { invoiceFind, sendInvoiceEmail } from '../../redux/invoices/action';
import { useDispatch, useSelector } from 'react-redux';
import { findVendorBankDetail } from '../../redux/bankDetails/action';
import { commonFn } from '../../util/commonFn';
import { openSnackBarLayout } from '../../redux/common/action';

export const Invoices = ({
  match,
  data = [],
  head = [],
  tabs = [],
  module,
  count = 0,
  pagination = [],
  skip = 0,
  errorMessage,
  hiddenKeys = [],
  inProgress = false,
  hiddenAction = [],
}) => {
  const history = useHistory();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [isSendButtonClicked, setIsSendButtonClicked] = useState(false);
  const [formDataSet, setFormDataSet] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const {
    invoiceFindProcess,
    invoiceFindError,
    invoiceFindData,
    vendorBankFindProcess,
    vendorBankFindError,
    vendorBankFindData,
    invoiceSendEmailProcess,
    invoiceSendEmailError,
    invoiceSendEmailData,
  } = useSelector(({ invoices, vendorBankDetail }) => ({
    ...invoices,
    ...vendorBankDetail,
  }));

  // set form data
  useEffect(() => {
    setFormData(() => ({
      list: SendInvoiceFormData,
    }));
    setIsFormSubmitted(() => false);
  }, []);

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const invoiceId = data.row[0].value;
    if (module === 'company') {
      if (
        invoiceId &&
        data?.action?.key === 'invoice_attachment_name' &&
        !data?.action?.type &&
        data.row[9]?.value
      ) {
        window.open(data.row[9]?.value);
        return false;
      } else if ((invoiceId && data?.action?.key) || (invoiceId && data?.action?.type === 'view')) {
        history.push(`/company/invoices/view/${invoiceId}`);
      } else if (invoiceId && data?.action?.key == 'edit') {
        history.push(`/company/invoices/edit/${invoiceId}`);
      }
    } else if (module === 'accounts') {
      if (
        invoiceId &&
        data?.action?.key === 'invoice_attachment_name' &&
        !data?.action?.type &&
        data.row[9]?.value
      ) {
        window.open(data.row[9]?.value);
        return false;
      } else if ((invoiceId && data?.action?.key) || (invoiceId && data?.action?.type === 'view')) {
        history.push(`/accounts/invoices/view/${invoiceId}`);
      } else if (invoiceId && data?.action?.type === 'edit') {
        history.push(`/accounts/invoices/edit/${invoiceId}`);
      }

      // send email invoice
      if (data && data?.action?.type === 'email' && invoiceId) {
        invoiceId && dispatch(invoiceFind(invoiceId));
        invoiceId && dispatch(findVendorBankDetail());
        setIsSendButtonClicked(() => true);
      }
    }
  };

  // set vendor bank data to form data
  useEffect(() => {
    if (
      !vendorBankFindProcess &&
      !vendorBankFindError &&
      vendorBankFindData &&
      isSendButtonClicked
    ) {
      const ccEmail = {
        label: vendorBankFindData?.data?.cc_email,
        value: vendorBankFindData?.data?.cc_email,
      };
      setFormData((data) => ({
        ...data,
        values: {
          ...data.values,
          cc_email: vendorBankFindData?.data?.cc_email ? ccEmail : null,
        },
        options: {
          ...data.options,
          ccEmail: vendorBankFindData?.data?.cc_email ? [ccEmail] : [],
        },
      }));
    }
  }, [vendorBankFindProcess, vendorBankFindError, vendorBankFindData]);

  // set invoice data to form data
  useEffect(() => {
    if (!invoiceFindError && !invoiceFindProcess && invoiceFindData && isSendButtonClicked) {
      setIsDialogOpened(() => true);

      const billingEmail = {
        label: invoiceFindData?.data?.billing_email,
        value: invoiceFindData?.data?.billing_email,
      };

      setFormData((data) => ({
        ...data,
        values: {
          ...invoiceFindData?.data,
          billing_email: invoiceFindData?.data?.billing_email ? billingEmail : null,
          invoiceId: invoiceFindData?.data?.id,
        },
        options: {
          ...data.options,
          billingEmail: invoiceFindData?.data?.billing_email ? [billingEmail] : [],
        },
      }));
    }
  }, [invoiceFindError, invoiceFindProcess, invoiceFindData]);

  // Table data
  const tableData = {
    head,
    count,
    data: data || [],
    skip: skip,
    clickOnCell: clickOnCell,
    pagination,
    errorMessage,
    hiddenKeys,
    inProgress,
    hiddenAction,
  };

  // close dialog function
  const closeDialog = () => {
    setIsDialogOpened(() => false);
    setIsSendButtonClicked(() => false);
    setFormDataSet(() => {});
  };

  // send invoice email success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!invoiceSendEmailProcess && !invoiceSendEmailError && invoiceSendEmailData) {
        dispatch(openSnackBarLayout(invoiceSendEmailData.message, 'success', 1000));
        closeDialog();
      } else if (invoiceSendEmailError) {
        dispatch(openSnackBarLayout(invoiceSendEmailError?.message, 'error', 1000));
        closeDialog();
      }
    }
  }, [invoiceSendEmailProcess, invoiceSendEmailError, invoiceSendEmailData]);

  // form submit
  const formSubmit = () => {
    const request = {};
    request.id = formData?.values?.invoiceId;
    request.billing_email =
      (formDataSet?.billing_email && formDataSet?.billing_email) ||
      formData?.values?.billing_email?.value;
    request.cc_email =
      (formDataSet?.cc_email && formDataSet?.cc_email) || formData?.values?.cc_email;
    request.message = formDataSet?.message;

    setIsFormSubmitted(() => true);
    dispatch(sendInvoiceEmail(request));
  };

  const onChangeEvent = (data) => {
    const dataArray = {};

    if (data?.field?.name === 'billing_email') {
      const billing_email = commonFn.getIdFromMultiValue(data?.value);
      dataArray.billing_email = billing_email;
    }

    if (data?.field?.name === 'cc_email') {
      const cc_email = commonFn.getIdFromMultiValue(data?.value);
      dataArray.cc_email = cc_email;
    }

    if (data?.field?.name === 'message') {
      dataArray.message = data?.value?.target?.value;
    }

    setFormDataSet((formDataSet) => ({
      ...formDataSet,
      ...dataArray,
    }));
  };

  return (
    <>
      <TabCustom {...tabs} />
      <Box mb={4}>
        {!inProgress && <TableCustom {...tableData} />}

        {inProgress && (
          <div className="loader">
            <CircularProgress />
          </div>
        )}

        {isDialogOpened && (
          <DialogBox
            {...formData}
            isOpened={isDialogOpened}
            closeDialog={closeDialog}
            changeEvent={onChangeEvent}
            formSubmit={formSubmit}
          />
        )}
      </Box>
    </>
  );
};
