import React, { useState, useEffect } from 'react';
import { Tabs, InvoiceTabs } from '../../data/AccountsData';
import { FormInputsData, FileUpload } from '../../data/CreateInvoiceData';
import { TabCustom, FormCustom } from '.';
import { useLocation, useHistory } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { createInvoice, invoiceFind, updateInvoice } from '../../redux/invoices/action';
import {
  citiesFindAll,
  statesFindAll,
  countriesFindAll,
  createCity,
  createState,
  createCountry,
} from '../../redux/cities/action';
import { clientsFindAll } from '../../redux/clients/action';
import { CircularProgress, Grid, Box } from '@material-ui/core';
import { openSnackBarLayout } from '../../redux/common/action';
import { invoiceStatusFindAll } from '../../redux/master/action';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { findVendorBankDetail } from '../../redux/bankDetails/action';
import { clientFind, endClientFind } from '../../redux/clients/action';
import { DialogBox } from '../common';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import './FormCustom.scss';

export const AddOrEditInvoice = ({
  match,
  isEdit,
  isView,
  hiddenKey,
  readOnly,
  url,
  module,
  options = {},
  values = {},
}) => {
  const {
    invoiceCreateProcess,
    invoiceCreateError,
    invoiceCreateData,
    invoiceFindProcess,
    invoiceFindError,
    invoiceFindData,
    invoiceUpdateProcess,
    invoiceUpdateError,
    invoiceUpdateData,
    cityFindAllProcess,
    cityFindAllError,
    cityFindAllData,
    stateFindAllProcess,
    stateFindAllError,
    stateFindAllData,
    countryFindAllProcess,
    countryFindAllError,
    countryFindAllData,
    countryCreateProcess,
    countryCreateError,
    countryCreateData,
    stateCreateProcess,
    stateCreateError,
    stateCreateData,
    cityCreateProcess,
    cityCreateError,
    cityCreateData,
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    invoiceStatusFindAllProcess,
    invoiceStatusFindAllError,
    invoiceStatusFindAllData,
    vendorBankFindProcess,
    vendorBankFindError,
    vendorBankFindData,
    clientFindError,
    clientFindData,
    clientFindProcess,
  } = useSelector(({ invoices, citiesNew, clients, invoiceStatus, vendorBankDetail }) => ({
    ...invoices,
    ...citiesNew,
    ...clients,
    ...invoiceStatus,
    ...vendorBankDetail,
  }));

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [fileUpload, setFileUpload] = useState(FileUpload);
  const [isUploading, setIsUploading] = useState(false);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);

  const MySwal = withReactContent(Swal);

  // tabs
  let tabsData = [];

  if (location?.pathname.indexOf('company') !== -1) {
    module = 'company';
    tabsData = Tabs;
  } else if (location?.pathname.indexOf('accounts') !== -1) {
    tabsData = InvoiceTabs;
    module = 'accounts';
  }

  useEffect(() => {
    isEdit &&
      module === 'company' &&
      setReadOnlyKeys(() => [
        'invoice_number',
        'invoiced_date',
        'due_date',
        'total_amount',
        'vendor_bank_account_number',
        'ifsc_code',
        'swift_code',
        'branch',
        'more_bank_details',
        'UploadInvoice',
        'company_id',
        'billing_email',
        'gstin',
        'billing_address',
        'billing_city_id',
        'billing_zipcode',
        'billing_state_id',
        'billing_country_id',
      ]);
  }, [invoiceFindData]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  // set form data
  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? invoiceFindProcess : false,
      options: {},
    }));
    setIsRouteChange(true);
    match?.params?.id && dispatch(invoiceFind(match?.params?.id));
    dispatch(citiesFindAll());
    dispatch(statesFindAll());
    dispatch(countriesFindAll());
    dispatch(clientsFindAll());
    dispatch(invoiceStatusFindAll());
    dispatch(findVendorBankDetail());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
    dispatch(endClientFind());
  }, [match?.params?.id]);

  // invoice data
  useEffect(() => {
    if (isEdit) {
      if (!invoiceFindProcess && !invoiceFindError && invoiceFindData?.data) {
        const billingEmail = {
          label: invoiceFindData?.data?.billing_email,
          value: invoiceFindData?.data?.billing_email,
        };
        setFormData((data) => ({
          ...data,
          values: {
            ...invoiceFindData?.data,
            billing_email: invoiceFindData?.data?.billing_email ? billingEmail : null,
          },
          options: {
            ...data.options,
            billing_email: invoiceFindData?.data?.billing_email ? [billingEmail] : [],
          },
          inProgress: false,
        }));
      } else if (!invoiceFindProcess && invoiceFindError && !invoiceFindData?.data) {
        const error = invoiceFindData?.message || invoiceFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [invoiceFindProcess, invoiceFindError, invoiceFindData]);

  // set vendor bank details
  useEffect(() => {
    if (!isEdit) {
      if (!vendorBankFindProcess && !vendorBankFindError && vendorBankFindData?.data) {
        setFormData((data) => ({
          ...data,
          values: vendorBankFindData?.data,
        }));
      }
    }
  }, [vendorBankFindProcess, vendorBankFindError, vendorBankFindData]);

  // Country
  useEffect(() => {
    if (!countryFindAllProcess && !countryFindAllError && countryFindAllData?.data) {
      const billing_country =
        (countryFindAllData &&
          countryFindAllData?.data?.list?.map((country) => {
            return { label: country?.country, value: country?.id };
          })) ||
        [];

      const getId = formData.values?.billing_country_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.billing_country_id?.value, billing_country)
            .value
        : invoiceFindData?.data?.billing_country_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && invoiceFindData?.data
            ? {
                ...data.values,
                billing_country_id: commonFn.getMultiValueFromId(getId, billing_country)[0],
              }
            : data.values,
        options: { ...data.options, billing_country },
      }));
    }
  }, [countryFindAllProcess, countryFindAllError, countryFindAllData, invoiceFindData]);

  // City
  useEffect(() => {
    if (!cityFindAllProcess && !cityFindAllError && cityFindAllData?.data) {
      const billing_city =
        (cityFindAllData &&
          cityFindAllData?.data?.list?.map((city) => {
            return { label: city?.city, value: city?.id };
          })) ||
        [];

      const getId = formData.values?.billing_city_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.billing_city_id?.value, billing_city).value
        : invoiceFindData?.data?.billing_city_id;

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && invoiceFindData?.data
            ? {
                ...data.values,
                billing_city_id: commonFn.getMultiValueFromId(getId, billing_city)[0],
              }
            : data.values,
        options: { ...data.options, billing_city },
      }));
    }
  }, [cityFindAllProcess, cityFindAllError, cityFindAllData, invoiceFindData]);

  // State
  useEffect(() => {
    if (!stateFindAllProcess && !stateFindAllError && stateFindAllData?.data) {
      const billing_state =
        (stateFindAllData &&
          stateFindAllData?.data?.list?.map((state) => {
            return { label: state?.state, value: state?.id };
          })) ||
        [];

      const getId = formData.values?.billing_state_id?.__isNew__
        ? commonFn.getIdfromSingleValue(formData.values.billing_state_id?.value, billing_state)
            .value
        : invoiceFindData?.data?.billing_state_id;

      setFormData((data) => ({
        ...data,
        values:
          (match?.params?.id && invoiceFindData?.data) || clientFindData?.data
            ? {
                ...data.values,
                billing_state_id: commonFn.getMultiValueFromId(getId, billing_state)[0],
              }
            : data.values,
        options: { ...data.options, billing_state },
      }));
    }
  }, [stateFindAllProcess, stateFindAllError, stateFindAllData, invoiceFindData]);

  // clients
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData?.data) {
      const clients =
        (clientsFindAllData &&
          clientsFindAllData?.data?.list?.map((client) => {
            return { label: client?.company_name, value: client?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && invoiceFindData?.data
            ? {
                ...data.values,
                company_id: commonFn.getMultiValueFromId(
                  invoiceFindData?.data?.company_id,
                  clients,
                ),
              }
            : data.values,
        options: { ...data.options, clients },
      }));
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData, invoiceFindData]);

  // invoice status
  useEffect(() => {
    if (
      !invoiceStatusFindAllProcess &&
      !invoiceStatusFindAllError &&
      invoiceStatusFindAllData?.data
    ) {
      const invoiceStatus =
        (invoiceStatusFindAllData &&
          invoiceStatusFindAllData?.data?.list?.map((status) => {
            return { label: status?.status, value: status?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && invoiceFindData?.data
            ? {
                ...data.values,
                invoice_status_id: commonFn.getMultiValueFromId(
                  invoiceFindData?.data?.invoice_status_id,
                  invoiceStatus,
                ),
              }
            : data.values,
        options: { ...data.options, invoiceStatus },
      }));
    }
  }, [
    invoiceStatusFindAllProcess,
    invoiceStatusFindAllError,
    invoiceStatusFindAllData,
    invoiceFindData,
  ]);

  // change event
  const changeEvent = async (data) => {
    if (data?.value && data?.value?.__isNew__) {
      // if country id
      if (data?.field?.name === 'billing_country_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_country_id: data?.value },
        }));
        createNewCountry(data?.value?.value);
      }

      // if state id
      if (data?.field?.name === 'billing_state_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_state_id: data?.value },
        }));
        createNewState(data?.value?.value);
      }

      // if city id
      if (data?.field?.name === 'billing_city_id') {
        setFormData((form) => ({
          ...form,
          values: { ...form.values, ...data.watch, billing_city_id: data?.value },
        }));
        createNewCity(data?.value?.value);
      }
    }

    // find client details based on client id
    if (data?.field && data?.field?.name === 'company_id') {
      if (!data?.value?.value) {
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            ...data.watch,
            billing_address: '',
            billing_city_id: null,
            billing_country_id: null,
            billing_state_id: null,
            billing_email: null,
            gstin: '',
            billing_zipcode: '',
          },
        }));
      } else {
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            ...data.watch,
          },
        }));
        dispatch(clientFind(data?.value?.value));
      }
    }
  };

  // set client data on change event
  useEffect(() => {
    if (!clientFindProcess && !clientFindError && clientFindData?.data) {
      const billingEmail = {
        label: clientFindData?.data?.billing_email,
        value: clientFindData?.data?.billing_email,
      };

      setFormData((form) => ({
        ...form,
        options: { ...form.options, billing_email: [billingEmail] },
        values: {
          ...form.values,
          ...clientFindData?.data,
          billing_city_id: {
            label: clientFindData?.data?.billing_city,
            value: clientFindData?.data?.billing_city_id,
          },
          billing_country_id: {
            label: clientFindData?.data?.billing_country,
            value: clientFindData?.data?.billing_country_id,
          },
          billing_state_id: {
            label: clientFindData?.data?.billing_state,
            value: clientFindData?.data?.billing_state_id,
          },
          billing_email: billingEmail,
          gstin: clientFindData?.data?.gstin,
        },
      }));
    } else if (!clientFindProcess && !clientFindError && !clientFindData) {
      setFormData((form) => ({
        ...form,
        options: { ...form.options },
        values: {
          ...form.values,
          billing_address: '',
          billing_city_id: null,
          billing_country_id: null,
          billing_state_id: null,
          billing_email: null,
          gstin: '',
          billing_zipcode: '',
        },
      }));
    }
  }, [clientFindProcess, clientFindError, clientFindData]);

  // create country onchange event
  const createNewCountry = (country) => {
    const countryData = {
      country: country,
    };
    dispatch(createCountry(countryData));
  };

  // Refresh country after creating
  useEffect(() => {
    if (!countryCreateProcess && !countryCreateError && countryCreateData?.data) {
      const countryValue = formData.values?.billing_country_id;

      const country = countryValue &&
        countryValue?.__isNew__ && {
          label: countryCreateData?.data?.country,
          value: countryCreateData?.data?.id,
        };

      if (countryValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_country_id: country,
          },
        }));
      }
    }
  }, [countryCreateProcess, countryCreateError, countryCreateData]);

  // create state onchange event
  const createNewState = (state) => {
    const stateData = {
      state: state,
    };
    dispatch(createState(stateData));
  };

  // Refresh state after creating new
  useEffect(() => {
    if (!stateCreateProcess && !stateCreateError && stateCreateData?.data) {
      const stateValue = formData.values?.billing_state_id;
      const state = stateValue &&
        stateValue?.__isNew__ && {
          label: stateCreateData?.data?.state,
          value: stateCreateData?.data?.id,
        };

      if (stateValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_state_id: state,
          },
        }));
      }
    }
  }, [stateCreateProcess, stateCreateError, stateCreateData]);

  // create city onchange event
  const createNewCity = (city) => {
    const cityData = {
      city: city,
    };
    dispatch(createCity(cityData));
  };

  // Refresh city after creating new
  useEffect(() => {
    if (!cityCreateProcess && !cityCreateError && cityCreateData?.data) {
      const cityValue = formData.values?.billing_city_id;
      const city = cityValue &&
        cityValue?.__isNew__ && {
          label: cityCreateData?.data?.city,
          value: cityCreateData?.data?.id,
        };

      if (cityValue?.__isNew__) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            billing_city_id: city,
          },
        }));
      }
    }
  }, [cityCreateProcess, cityCreateError, cityCreateData]);

  // create invoice success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!invoiceCreateProcess && !invoiceCreateError && invoiceCreateData) {
        dispatch(openSnackBarLayout(invoiceCreateData.message, 'success', 1000));
        setIsLoading(() => false);
        history.push(`/accounts/invoices/view/${invoiceCreateData?.data?.id}`);
      } else if (invoiceCreateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(invoiceCreateError?.message, 'error', 1000));
      }
    }
  }, [invoiceCreateProcess, invoiceCreateError, invoiceCreateData]);

  // update invoice success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!invoiceUpdateProcess && !invoiceUpdateError && invoiceUpdateData) {
        dispatch(openSnackBarLayout(invoiceUpdateData.message, 'success', 1000));
        setIsLoading(() => false);
        if (module === 'accounts') {
          history.push(`/accounts/invoices/view/${invoiceUpdateData?.data?.id}`);
        } else if (module === 'company') {
          history.push(`/company/invoices/view/${invoiceUpdateData?.data?.id}`);
        }
      } else if (invoiceUpdateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(invoiceUpdateData?.message, 'error', 1000));
      }
    }
  }, [invoiceUpdateProcess, invoiceUpdateError, invoiceUpdateData]);

  // upload invoice attachment
  const invoiceFileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'invoices');
      formData.append('file', file);
      setIsUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            invoice_attachment: result?.data?.uploaded_url,
            invoice_attachment_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('Invoice attachment is not uploaded', 'error', 1000));
      }
    }
  };

  // remove uploaded invoice
  const removeInvoiceAttachment = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        invoice_attachment: '',
        invoice_attachment_name: '',
      },
    }));
  };

  // Upload Invoice component
  const UploadInvoice = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={fileUpload?.xs || 12}
            sm={fileUpload?.sm || 12}
            md={fileUpload?.md || 6}
            lg={fileUpload?.lg || 4}
            xl={fileUpload?.xl || 4}
          >
            <Box className={fileUpload?.formElementClass}>
              {/* <label className="interview-label">{fileUpload.label}</label> */}
              {isUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isUploading && (
                <>
                  <input
                    accept={fileUpload?.accept}
                    className={fileUpload.fieldClass}
                    id={fileUpload?.id}
                    type={fileUpload?.type}
                    onChange={(e) => invoiceFileChange(e, watch)}
                  />
                  {!formData.values?.invoice_attachment && module === 'accounts' && (
                    <label htmlFor={fileUpload?.id} className={fileUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {fileUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.invoice_attachment && (
                    <div className="file-section">
                      {readOnlyKeys.indexOf('UploadInvoice') === -1 && (
                        <>
                          <div
                            className={'delete-file'}
                            onClick={() => {
                              removeInvoiceAttachment(watch);
                            }}
                          >
                            <HighlightOffIcon />
                          </div>
                        </>
                      )}

                      <a
                        href={formData.values?.invoice_attachment}
                        target="_blank"
                        className={fileUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.invoice_attachment_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End invoice attachment

  // form submit
  const formSubmit = (data) => {
    const request = data;
    request.company_id =
      (data?.company_id?.value && data?.company_id?.value) ||
      (formData?.values?.company_id &&
        commonFn.getIdFromMultiValue(formData?.values?.company_id[0]).value);
    request.invoice_status_id =
      (data?.invoice_status_id?.value && data?.invoice_status_id?.value) ||
      (formData?.values?.invoice_status_id &&
        commonFn.getIdFromMultiValue(formData?.values?.invoice_status_id[0]).value);
    request.billing_country_id = commonFn.getIdFromMultiValue(data?.billing_country_id).value;
    request.billing_city_id = commonFn.getIdFromMultiValue(data?.billing_city_id).value;
    request.billing_state_id = commonFn.getIdFromMultiValue(data?.billing_state_id).value;
    request.billing_email = commonFn.getIdFromMultiValue(data?.billing_email).value;

    if (isEdit) {
      setIsLoading(() => true);
      setIsFormSubmitted(() => true);
      dispatch(updateInvoice(match?.params?.id, request));
    } else {
      MySwal.fire({
        title: 'Are you sure?',
        html: `You are raising invoice to <span class="text-yellow"><b> ${data?.company_name} </b></span> for the amount <span class="text-yellow"><b> ${data?.total_amount}</b></span>.`,
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          setIsFormSubmitted(() => true);
          setIsLoading(() => true);
          dispatch(createInvoice(request));
        }
      });
    }
  };

  // button text
  const buttonSection = {
    isRequired: true,
    list: [{ viewText: 'Edit', editText: 'Update Invoice', createText: 'Create Invoice' }],
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && (
        <FormCustom
          {...formData}
          changeEvent={changeEvent}
          formSubmit={formSubmit}
          renderer={{ UploadInvoice }}
          readOnlyKeys={readOnlyKeys}
          isLoading={isLoading}
          buttonSection={buttonSection}
        />
      )}

      {isRouteChange && (
        <div className="loader align-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
