import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Profile } from '.';

export const ProfileRouter = (props) => {
  return (
    <>
      <Switch>
        <Route path={'/profile'} component={Profile} />
      </Switch>
    </>
  );
};
