import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Box,
  Tooltip,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import './Table.scss';
import { colors } from '../../theme';

export const TableCustom = ({
  data = [],
  head = [],
  key = 'key',
  clickOnCell = () => {},
  errorMessage = 'No data found',
  pagination = {},
  hiddenKeys = [],
  aliasObject = {},
  count = 0,
  skip = 0,
  hiddenAction = [],
  toolTipKey = [],
  tableClass = '',
} = {}) => {
  const {
    isShowFirstButton = true,
    isShowLastButton = true,
    numberOfPages = 0,
    currentPage = 0,
    handleChange = () => {},
  } = pagination;

  const tbodyData =
    data &&
    data.map((value) => {
      return head.map((thead) => {
        const multiValue = [];
        const groupValue = [];
        if (thead?.keys?.length && value[thead[key]]?.length) {
          value[thead[key]].map((innerValue, index) => {
            thead?.keys?.map((multiTHead) => {
              multiValue.push({ ...multiTHead, value: innerValue[multiTHead.key] });
            });
          });
        }

        return {
          ...thead,
          value: value[thead[key]],
          multiValue: multiValue,
        };
      });
    });

  return (
    <>
      <div className="top-pagination">
        {/* Table pagination */}
        <div className="table-pagination">
          {numberOfPages > 0 && (
            <Pagination
              count={numberOfPages}
              showFirstButton={isShowFirstButton}
              showLastButton={isShowLastButton}
              page={currentPage}
              onChange={handleChange}
            />
          )}
        </div>

        {/* display rows count */}
        <div className="table-rows-counter">
          <p>
            Showing {count > 0 ? skip + 1 : skip} to {skip + (data && data.length)} of {count}{' '}
            entries
          </p>
        </div>
      </div>

      <TableContainer component={Paper} style={{ width: '100%', marginTop: '20px' }}>
        <Table className={'table ' + tableClass}>
          <TableHead>
            <TableRow key={'thead-key'}>
              {head?.map((thead, index) => {
                if (hiddenKeys && hiddenKeys.indexOf(thead.hiddenKey) !== -1) {
                  return null;
                }
                return (
                  <TableCell
                    hidden={thead?.hide}
                    key={`thead_${index}`}
                    className={thead?.headClass}
                  >
                    {aliasObject[thead.aliasLabel] || thead?.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tbodyData &&
              tbodyData?.map((row, trowIndex) => (
                <TableRow
                  key={trowIndex}
                  onClick={(event) =>
                    clickOnCell({ action: { type: 'view' }, row, trowIndex }, event)
                  }
                >
                  {row?.map((tdValue, tdIndex) => {
                    if (hiddenKeys.indexOf(tdValue.hiddenKey) !== -1) {
                      return null;
                    }
                    return (
                      <React.Fragment key={`td_${tdIndex}`}>
                        {/* file/image */}
                        {tdValue?.type === 'file' && (
                          <TableCell hidden={tdValue?.hide}>
                            {(tdValue?.value?.length > 2 && (
                              <img className={tdValue?.imageClass} src={tdValue?.value} />
                            )) || <span className={tdValue?.bodyClass}>{tdValue?.value}</span>}
                          </TableCell>
                        )}

                        {tdValue?.type === 'text' && (
                          <TableCell
                            hidden={tdValue?.hide}
                            onClick={(event) =>
                              clickOnCell({ action: { key: tdValue?.key }, row, trowIndex }, event)
                            }
                          >
                            {/* toolTipKey */}
                            {(toolTipKey.indexOf(tdValue?.toolTipKey) !== -1 && (
                              <Tooltip title={tdValue?.toolTipText}>
                                <span className={tdValue?.bodyClass}> {tdValue?.value}</span>
                              </Tooltip>
                            )) || <span className={tdValue?.bodyClass}> {tdValue?.value}</span>}
                          </TableCell>
                        )}

                        {tdValue?.type === 'link' && (
                          <TableCell hidden={tdValue?.hide}>
                            <a
                              href={tdValue?.link}
                              target={tdValue?.target}
                              className={tdValue?.bodyClass}
                            >
                              {tdValue?.value}
                            </a>
                          </TableCell>
                        )}

                        {/* If type array - For array data */}
                        {tdValue?.type === 'array' && (
                          <TableCell hidden={tdValue?.hide}>
                            <span className={tdValue?.bodyClass}>
                              {tdValue?.multiValue?.map((multiVal, multiIndex) => (
                                <span
                                  className={multiVal?.arrayClass}
                                  hidden={multiVal?.hide}
                                  key={`mutli_${multiIndex}`}
                                >
                                  {multiVal?.value}
                                </span>
                              ))}
                            </span>
                          </TableCell>
                        )}
                        {/* End array check */}

                        {tdValue?.type === 'actions' && (
                          <TableCell className={tdValue?.bodyClass} hidden={tdValue?.hide}>
                            <Box>
                              {tdValue?.actions?.map((action, actionIndex) => (
                                <>
                                  {hiddenAction.indexOf(action.text) === -1 && (
                                    <span
                                      key={`action_${actionIndex}`}
                                      className={action.actionClass}
                                      onClick={(event) =>
                                        clickOnCell(
                                          { action, row, actionIndex, tdIndex, trowIndex, tdValue },
                                          event,
                                        )
                                      }
                                    >
                                      <Tooltip
                                        key={`tooltip_${actionIndex}`}
                                        title={action.text}
                                        arrow
                                      >
                                        <action.icon className={action.bodyClass} />
                                      </Tooltip>
                                    </span>
                                  )}
                                </>
                              ))}
                            </Box>
                          </TableCell>
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              ))}
            {tbodyData?.length === 0 && (
              <TableRow key={'error-message'}>
                <TableCell
                  colSpan={head?.length}
                  align="center"
                  style={{ color: colors?.primary?.orange100 }}
                >
                  {tbodyData?.length === 0 && errorMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {/* Table pagination */}
        <div className="bottom-pagination">
          <div className="table-pagination">
            {numberOfPages > 0 && (
              <Pagination
                count={numberOfPages}
                showFirstButton={isShowFirstButton}
                showLastButton={isShowLastButton}
                page={currentPage}
                onChange={handleChange}
              />
            )}
          </div>

          {/* display rows count */}
          <div className="table-rows-counter">
            <p>
              Showing {count > 0 ? skip + 1 : skip} to {skip + (data && data.length)} of {count} {' '}
              entries
            </p>
          </div>
        </div>
      </TableContainer>
    </>
  );
};
