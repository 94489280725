import { getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// User Types Find All
const startUserTypesFind = () => ({
  type: Types.START_USER_TYPES_FIND,
  payload: {},
});

const endUserTypesFind = (success, error) => ({
  type: Types.END_USER_TYPES_FIND,
  payload: {
    success,
    error,
  },
});

export const userTypesFind = () => async (dispatch) => {
  dispatch(startUserTypesFind());
  const [response, error] = await getService(`${apiList.userTypes}`);
  dispatch(endUserTypesFind(response, error));
};


// User roles find all
const startUserRolesFind = () => ({
  type: Types.START_USER_ROLES_FIND,
  payload: {},
});

const endUserRolesFind = (success, error) => ({
  type: Types.END_USER_ROLES_FIND,
  payload: {
    success,
    error,
  },
});

export const userRolesFind = () => async (dispatch) => {
  dispatch(startUserRolesFind());
  const [response, error] = await getService(`${apiList.userRoles}`);
  dispatch(endUserRolesFind(response, error));
};