import { Types } from './types';

const initState = {
  positionsFindAllProcess: false,
  positionsFindAllError: null,
  positionsFindAllData: null,

  createPositionProcess: false,
  createPositionError: null,
  createPositionData: null,

  positionFindProcess: false,
  positionFindError: null,
  positionFindData: null,

  positionFindAllByClientProcess: false,
  positionFindAllByClientError: null,
  positionFindAllByClientData: null,

  positionDeleteProcess: false,
  positionDeleteError: null,
  positionDeleteData: null,

  updatePositionProcess: false,
  updatePositionError: null,
  updatePositionData: null,
};

export const positions = (state = initState, action) => {
  switch (action.type) {
    case Types.START_POSITIONS_FIND_ALL:
      return {
        ...state,
        positionsFindAllProcess: true,
        positionsFindAllError: null,
        positionsFindAllData: null,
        createPositionProcess: false,
        createPositionError: null,
        createPositionData: null,
        positionFindProcess: false,
        positionFindError: null,
        positionFindData: null,
      };
    case Types.END_POSITIONS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionsFindAllProcess: false,
        positionsFindAllError: error,
        positionsFindAllData: success,
      };
    }

    // position create
    case Types.START_CREATE_POSITION:
      return {
        ...state,
        createPositionProcess: true,
        createPositionError: null,
        createPositionData: null,
      };
    case Types.END_CREATE_POSITION: {
      const { success, error } = action.payload;
      return {
        ...state,
        createPositionProcess: false,
        createPositionError: error,
        createPositionData: success,
      };
    }

    // position find
    case Types.START_POSITION_FIND:
      return {
        ...state,
        positionFindProcess: true,
        positionFindError: null,
        positionFindData: null,
        createPositionProcess: false,
        createPositionError: null,
        createPositionData: null,
        updatePositionProcess: false,
        updatePositionError: null,
        updatePositionData: null,
      };
    case Types.END_POSITION_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionFindProcess: false,
        positionFindError: error,
        positionFindData: success,
      };
    }

    // position find all by client
    case Types.START_POSITION_FIND_ALL_BY_CLIENT:
      return {
        ...state,
        positionFindAllByClientProcess: true,
        positionFindAllByClientError: null,
        positionFindAllByClientData: null,
      };

    case Types.END_POSITION_FIND_ALL_BY_CLIENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionFindAllByClientProcess: false,
        positionFindAllByClientError: error,
        positionFindAllByClientData: success,
      };
    }

    // Delete position
    case Types.START_POSITION_DELETE:
      return {
        ...state,
        positionDeleteProcess: true,
        positionDeleteError: null,
        positionDeleteData: null,
      };

    case Types.END_POSITION_DELETE: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionDeleteProcess: false,
        positionDeleteError: error,
        positionDeleteData: success,
      };
    }

    // Update position
    case Types.START_POSITION_UPDATE:
      return {
        ...state,
        updatePositionProcess: true,
        updatePositionError: null,
        updatePositionData: null,
      };
    case Types.END_POSITION_UPDATE: {
      const { success, error } = action.payload;
      return {
        ...state,
        updatePositionProcess: false,
        updatePositionError: error,
        updatePositionData: success,
      };
    }

    default:
      return state;
  }
};
