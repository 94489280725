export const Types = {
  START_APPLICANTS_FIND_ALL: 'START_APPLICANTS_FIND_ALL',
  END_APPLICANTS_FIND_ALL: 'END_APPLICANTS_FIND_ALL',

  START_CREATE_APPLICANT: 'START_CREATE_APPLICANT',
  END_CREATE_APPLICANT: 'END_CREATE_APPLICANT',

  START_APPLICANT_FIND: 'START_APPLICANT_FIND',
  END_APPLICANT_FIND: 'END_APPLICANT_FIND',

  START_APPLICANT_SOURCE_FIND_ALL: 'START_APPLICANT_SOURCE_FIND_ALL',
  END_APPLICANT_SOURCE_FIND_ALL: 'END_APPLICANT_SOURCE_FIND_ALL',

  START_APPLICANT_DELETE: 'START_APPLICANT_DELETE',
  END_APPLICANT_DELETE: 'END_APPLICANT_DELETE',

  START_APPLICANT_UPDATE: 'START_APPLICANT_UPDATE',
  END_APPLICANT_UPDATE: 'END_APPLICANT_UPDATE',

  START_SOURCE_CREATE: 'START_SOURCE_CREATE',
  END_SOURCE_CREATE: 'END_SOURCE_CREATE',
};
