import { Email, Lock } from '@material-ui/icons';
import { Validation } from './CommonData';

// Register
export const RegFormInputs = {
  headLine: 'Register',
  headLineClass: 'heading',
  subHeadLine: 'Please register to Quark account',
  subHeadLineClass: 'subheading',
  brand: {
    className: 'brand-logo'
  },
  data: [
    {
      label: 'First Name',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'first_name',
      placeholder: 'First Name',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
    {
      label: 'Last Name',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'last_name',
      placeholder: 'Last Name',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
    {
      label: 'Email',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'email',
      placeholder: 'Email',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
    {
      label: 'Password',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'first_name',
      placeholder: 'Password',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
    {
      label: 'Confirm Password',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'confirm_password',
      placeholder: 'Confirm Password',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
  ],
};

// Login
export const LoginFormInputs = {
  className: 'sign login',
  brand: {
    className: 'brand-logo'
  },
  header: {
    className: 'mx-21',
    heading: {
      title: 'Login',
      className: 'heading',
    },
    subheading: {
      title: 'Please login to your Quark account',
      className: 'subheading',
    },
  },
  footer: {
    link: {
      className: '',
      title: 'Forgot your password?',
      url: '/forgot-password',
    },
    buttonText: {
      className: '',
      title: 'Login',
    },
  },
  data: [
    {
      label: 'Email',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      name: 'email',
      placeholder: 'Email',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'text-box',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      icon: Email,
      isRequired: true,
      pattern: Validation.email,
      maxLength: 100,
      errors: [
        { type: 'required', message: 'Please enter your email' },
        { type: 'pattern', message: 'Please enter valid email' },
      ],
    },
    {
      label: 'Password',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      name: 'password',
      placeholder: 'Password',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'password',
      fieldClass: 'text-box',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      icon: Lock,
      isRequired: true,
      pattern: Validation.password,
      min: 4,
      max: 100,
      errors: [
        { type: 'required', message: 'Please enter your password' },
        {
          type: 'pattern',
          message:
            'Password must be more than 8 characters including uppercase, lowercase, numbers and special characters',
        },
      ],
    },
  ],
};

// Forgot Password Inputs
export const ForgotPasswordInputs = {
  className: 'sign forgot-password',
  brand: {
    className: 'brand-logo'
  },
  header: {
    className: 'mx-21',
    heading: {
      title: 'Forgot your password?',
      className: 'heading',
    },
    subheading: {
      title: 'Enter your email and hit submit, we will send you a link to reset your password.',
      className: 'subheading',
    },
  },
  footer: {
    link: {
      className: '',
      title: 'Login',
      url: '/login',
    },
    buttonText: {
      className: '',
      title: 'Submit',
    },
  },
  data: [
    {
      label: 'Email',
      name: 'email',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'email',
      placeholder: 'Email',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'text',
      fieldClass: 'text-box',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      icon: Email,
      isRequired: true,
      pattern: Validation.email,
      maxLength: 100,
      errors: [
        { type: 'required', message: 'Please enter your email' },
        { type: 'pattern', message: 'Please enter valid email' },
      ],
    },
  ],
};

// Forgot Password Inputs
export const ResetPasswordInputs = {
  className: 'sign forgot-password',
  brand: {
    className: 'brand-logo'
  },
  header: {
    className: 'mx-21',
    heading: {
      title: 'Reset your password?',
      className: 'heading',
    },
    subheading: {
      title: 'Create your new password by confirming your registered email.',
      className: 'subheading',
    },
  },
  footer: {
    link: {
      className: '',
      title: 'Login',
      url: '/login',
    },
    buttonText: {
      className: '',
      title: 'Reset Password',
    },
  },
  data: [
    // {
    //   label: 'Email',
    //   name: 'email',
    //   xs: 12,
    //   sm: 12,
    //   md: 12,
    //   lg: 12,
    //   xl: 12,
    //   key: 'email',
    //   placeholder: 'Email',
    //   hide: false,
    //   formControlClass: 'input-form-control',
    //   type: 'text',
    //   fieldClass: 'text-box',
    //   variant: 'outlined',
    //   margin: 'dense',
    //   formElementClass: 'form-input-box',
    //   icon: Email,
    //   isRequired: true,
    //   pattern: Validation.email,
    //   min: 4,
    //   max: 100,
    //   errors: [
    //     { type: 'required', message: 'Enter your email' },
    //     { type: 'pattern', message: 'Please enter a valid email' },
    //   ],
    // },
    {
      label: 'Password',
      name: 'new_password',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'new_password',
      placeholder: 'New Password',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'password',
      fieldClass: 'text-box',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      icon: Email,
      isRequired: true,
      pattern: Validation.password,
      min: 4,
      max: 100,
      errors: [
        { type: 'required', message: 'Enter your new password' },
        {
          type: 'pattern',
          message:
            'The password must be more than 8 characters including uppercase, lowercase, numbers and special characters.',
        },
      ],
    },
    {
      label: 'Confirm Password',
      name: 'confirm_password',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'confirm_password',
      placeholder: 'Confirm Password',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'password',
      fieldClass: 'text-box',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      icon: Email,
      isRequired: true,
      pattern: Validation.password,
      min: 4,
      max: 100,
      errors: [
        { type: 'required', message: 'Please re-enter your new password' },
        { type: 'pattern', message: 'The password do not match' },
      ],
    },
  ],
};

export const verifyInputs = {
  className: 'sign login verify',
  brand: {
    className: 'brand-logo'
  },
  isLogoHide: true,
};
