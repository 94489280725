import { getService, postService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Create employee
const startCreateEmployee = () => ({
  type: Types.START_CREATE_EMPLOYEE,
  payload: {},
});

const endCreateEmployee = (success, error) => ({
  type: Types.END_CREATE_EMPLOYEE,
  payload: {
    success,
    error,
  },
});

export const createEmployee = (data) => async (dispatch) => {
  dispatch(startCreateEmployee());
  const [response, error] = await postService(apiList.employees, data);
  dispatch(endCreateEmployee(response, error));
};

// Employee Find All
const startEmployeeFindAll = () => ({
  type: Types.START_EMPLOYEE_FIND_ALL,
  payload: {},
});

const endEmployeeFindAll = (success, error) => ({
  type: Types.END_EMPLOYEE_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const employeeFindAll = 
  (skip = 0, limit = 10, data) =>
  async (dispatch) => {
    dispatch(startEmployeeFindAll());
    const [response, error] = await getService(
      `${apiList.employees}?skip=${skip}&limit=${limit}`,
      data,
    );
    dispatch(endEmployeeFindAll(response, error));
};

// Employee Find
const startEmployeeFind = () => ({
  type: Types.START_EMPLOYEE_FIND,
  payload: {},
});

const endEmployeeFind = (success, error) => ({
  type: Types.END_EMPLOYEE_FIND,
  payload: {
    success,
    error,
  },
});

export const employeeFind = (id) => async (dispatch) => {
  dispatch(startEmployeeFind());
  const [response, error] = await getService(`${apiList.employees}/${id}`);
  dispatch(endEmployeeFind(response, error));
};

// Employee Update
const startEmployeeUpdate = () => ({
  type: Types.START_EMPLOYEE_UPDATE,
  payload: {},
});

const endEmployeeUpdate = (success, error) => ({
  type: Types.END_EMPLOYEE_UPDATE,
  payload: {
    success,
    error,
  },
});

export const updateEmployee = (id, data) => async (dispatch) => {
  dispatch(startEmployeeUpdate());
  const [response, error] = await putService(`${apiList.employees}/${id}`, data);
  dispatch(endEmployeeUpdate(response, error));
};
