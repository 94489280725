import React, { useState, useEffect } from 'react';
import { HrmsTabs, EmployeeHead, ResponseData } from '../../../data/EmployeeData';
import { commonFn } from '../../../util/commonFn';
import { useHistory } from 'react-router-dom';
import { Employees } from '../../common';
import { employeeFindAll } from '../../../redux/hrms/action';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

export const HrmsEmployees = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const { employeeFindAllProcess, employeeFindAllError, employeeFindAllData } = useSelector(
    ({ employees }) => ({
      ...employees,
    }),
  );

  const tabs = {
    ...HrmsTabs,
    list: commonFn.updateLink(HrmsTabs.list, match),
  };

  // Set page title
  useEffect(() => {
    document.title = 'Quark - HRMS | Employees';
  }, []);

  useEffect(() => {
    dispatch(employeeFindAll(skip, limit));
  }, [skip]);

  // extract employee data from API response
  const empResponse = employeeFindAllData?.data?.list;
  const employeeData =
    empResponse &&
    empResponse.map((emp) => {
      const text = [{ text: emp?.full_name }, { text: emp?.flipkoins_email }];
      return { ...emp, full_name: text };
    });

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(employeeFindAll(limit * (value - 1), limit));
  };

  // Table data
  const tableData = {
    tabs: tabs,
    module: 'hrms',
    count: employeeFindAllData?.data?.count,
    skip: skip,
    data: employeeData,
    head: EmployeeHead,
    inProgress: employeeFindAllProcess || false,
    pagination: {
      numberOfPages: employeeFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: employeeFindAllError?.message,
    hiddenKeys: [],
  };

  return (
    <>
      <Employees {...tableData} />
    </>
  );
};
