import { deleteService, getService, postService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// holidays find all
const startHolidaysFindAll = () => ({
  type: Types.START_FIND_ALL_HOLIDAYS,
  payload: {},
});

const endHolidaysFindAll = (success, error) => ({
  type: Types.END_FIND_ALL_HOLIDAYS,
  payload: {
    success,
    error,
  },
});

export const findAllHolidays = () => async (dispatch) => {
    dispatch(startHolidaysFindAll());
    const [response, error] = await getService(`${apiList.holidays}`);
    dispatch(endHolidaysFindAll(response, error));
  };

// holiday find
const startFindHoliday = () => ({
  type: Types.START_FIND_HOLIDAY,
  payload: {},
});

const endFindHoliday = (success, error) => ({
  type: Types.END_FIND_HOLIDAY,
  payload: {
    success,
    error,
  },
});

export const holidayFind = (id) => async (dispatch) => {
  dispatch(startFindHoliday());
  const [response, error] = await getService(`${apiList.holidays}/${id}`);
  dispatch(endFindHoliday(response, error));
};

// create holiday
const startCreateHoliday = () => ({
  type: Types.START_CREATE_HOLIDAY,
  payload: {},
});

const endCreateHoliday = (success, error) => ({
  type: Types.END_CREATE_HOLIDAY,
  payload: {
    success,
    error,
  },
});

export const createHoliday = (data) => async (dispatch) => {
  dispatch(startCreateHoliday());
  const [response, error] = await postService(apiList.holidays, data);
  dispatch(endCreateHoliday(response, error));
};

// update holiday
const startUpdateHoliday = () => ({
  type: Types.START_UPDATE_HOLIDAY,
  payload: {},
});

const endUpdateHoliday = (success, error) => ({
  type: Types.END_UPDATE_HOLIDAY,
  payload: {
    success,
    error,
  },
});

export const updateHoliday = (id, data) => async (dispatch) => {
  dispatch(startUpdateHoliday());
  const [response, error] = await putService(`${apiList.holidays}/${id}`, data);
  dispatch(endUpdateHoliday(response, error));
};

// delete holiday
const startDeleteHoliday = () => ({
  type: Types.START_DELETE_HOLIDAY,
  payload: {},
});

const endDeleteHoliday = (success, error) => ({
  type: Types.END_DELETE_HOLIDAY,
  payload: {
    success,
    error,
  },
});

export const holidayDelete = (id) => async (dispatch) => {
  dispatch(startDeleteHoliday());
  const [response, error] = await deleteService(`${apiList.holidays}/${id}`);
  dispatch(endDeleteHoliday(response, error));
};


// holidays find all
const startHolidaysNoteFind = () => ({
  type: Types.START_FIND_HOLIDAY_NOTE,
  payload: {},
});

const endHolidaysNoteFind = (success, error) => ({
  type: Types.END_FIND_HOLIDAY_NOTE,
  payload: {
    success,
    error,
  },
});

export const findHolidaysNote = () => async (dispatch) => {
    dispatch(startHolidaysNoteFind());
    const [response, error] = await getService(`${apiList.holidaysNote}`);
    dispatch(endHolidaysNoteFind(response, error));
  };

// update holiday note
const startUpdateHolidayNote = () => ({
  type: Types.START_UPDATE_HOLIDAY_NOTE,
  payload: {},
});

const endUpdateHolidayNote = (success, error) => ({
  type: Types.END_UPDATE_HOLIDAY_NOTE,
  payload: {
    success,
    error,
  },
});

export const updateHolidayNote = (id, data) => async (dispatch) => {
  dispatch(startUpdateHolidayNote());
  const [response, error] = await putService(`${apiList.holidaysNote}/${id}`, data);
  dispatch(endUpdateHolidayNote(response, error));
};