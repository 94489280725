import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { closeSnackBarLayout } from '../../redux/common/action';
import { useDispatch, useSelector } from 'react-redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const { isOpenSnackBar, snackBarTime, snackBarMessage, snackBarSeverity } = useSelector(
    ({ common }) => ({
      ...common,
    }),
  );

  const handleClose = () => {
    dispatch(closeSnackBarLayout());
  };

  return (
    <div>
      <Snackbar open={isOpenSnackBar} autoHideDuration={snackBarTime || null} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
