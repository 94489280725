import React, { useEffect, useState } from 'react';
import { TableCustom, TabCustom } from '../common';
import { Box, CircularProgress } from '@material-ui/core';
import { PositionHead, Tabs } from '../../data/UsersData';
import '../positions/Positions.scss';
import { useHistory } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';
import { usersFindAll } from '../../redux/users/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteUser } from '../../redux/users/action';
// Start: Main Function
export const Users = ({ match }) => {
  const MySwal = withReactContent(Swal);

  const { usersFindAllError, usersFindAllData, usersFindAllProcess, deleteUserData, userInfo } =
    useSelector(({ users, sign }) => ({
      ...users,
      ...sign,
    }));
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [hiddenAction, setHiddenAction] = useState([]);
  const [hiddenTableCol, setHiddenTableCol] = useState([]);

  // Set page title
  useEffect(() => {
    document.title = 'Quark - User Management';
    if (userInfo?.data?.type_slug === 'client') {
      setHiddenTableCol((hiddenTableCol) => [...hiddenTableCol, 'company_logo']);
    }
  }, []);

  useEffect(() => {
    dispatch(usersFindAll(skip, limit));
  }, [skip]);

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const userId = data.row[0]?.value;

    if (userId && data?.action?.type === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-user') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }

      if (userId === userInfo?.data?.id) {
        commonFn.showPopup(
          `You can't update your information here. Please use profile section to update it`,
        );
        return false;
      }
      history.push(`/users/edit/${userId}`);
    }

    // view user
    if ((userId && data.action?.key) || (userId && data.action?.type === 'view')) {
      history.push(`/users/view/${userId}`);
    }

    // delete user
    if (data.action.type === 'delete') {
      if (commonFn.menuHiddenKeys.indexOf('delete-user') !== -1) {
        commonFn.showPopup(commonFn.deleteAccessError);
        return false;
      }

      if (userId === userInfo?.data?.id) {
        commonFn.showPopup(`You can't delete your information`);
        return false;
      }

      userId &&
        data.action.type === 'delete' &&
        MySwal.fire({
          title: 'Are you sure?',
          text: 'You want to delete the user',
          icon: 'warning',
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          result = result.isConfirmed && dispatch(deleteUser(userId));
          result &&
            MySwal.fire({
              title: 'Success!',
              text: 'User has been deleted',
              icon: 'success',
              iconColor: '#3085d6',
            });
        });
    }
  };

  // refresh users table after deleting
  useEffect(() => {
    !usersFindAllProcess && deleteUserData && dispatch(usersFindAll(skip, limit));
  }, [deleteUserData]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
  };

  // hide action based on the permission
  useEffect(() => {
    if (!usersFindAllProcess && !usersFindAllError && usersFindAllData) {
      if (commonFn.menuHiddenKeys.indexOf('edit-user') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Edit']);
      }
      if (commonFn.menuHiddenKeys.indexOf('delete-user') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Delete']);
      }
    }
  }, [usersFindAllProcess, usersFindAllError, usersFindAllData]);

  // hide the action column if edit and delete action is not
  useEffect(() => {
    if (
      hiddenAction &&
      hiddenAction.indexOf('Edit') !== -1 &&
      hiddenAction.indexOf('Delete') !== -1
    ) {
      setHiddenTableCol((hiddenTableCol) => [...hiddenTableCol, 'action']);
    }
  }, [hiddenAction]);

  const tableData = {
    hiddenAction: hiddenAction,
    count: usersFindAllData?.data?.count,
    skip: skip,
    data: usersFindAllData?.data?.list?.map((user) => ({
      ...user,
      name: `${user.first_name} ${user.last_name} ${userInfo?.data?.id === user.id ? '(You)' : ''}`,
    })),
    head: PositionHead,
    pagination: {
      numberOfPages: usersFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    clickOnCell: clickOnCell,
    errorMessage: usersFindAllError?.message,
  };

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  return (
    <div>
      <TabCustom {...tabs} />
      {usersFindAllProcess && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}
      {!usersFindAllProcess && (
        <>
          <Box mb={4}>
            <TableCustom {...tableData} hiddenKeys={hiddenTableCol} />
          </Box>
        </>
      )}
    </div>
  );
};
