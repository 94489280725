import React, { useState, useEffect } from 'react';
import { Tabs, HolidaysHead, ResponseData } from '../../../data/HolidaysData';
import { Holidays, TabCustom, HolidaysNote } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { findAllHolidays, findHolidaysNote } from '../../../redux/holidays/action';
import { CircularProgress } from '@material-ui/core';

export const CompanyHolidays = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const tabs = {
    ...Tabs,
    // list: commonFn.updateLink(Tabs.list, match),
  };

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Company | Holidays';
  }, []);

  const {
    holidaysFindAllProcess,
    holidaysFindAllError,
    holidaysFindAllData,
    holidaysNoteFindProcess,
    holidaysNoteFindError,
    holidaysNoteFindData,
  } = useSelector(({ holidays }) => ({
    ...holidays,
  }));

  useEffect(() => {
    dispatch(findAllHolidays(skip, limit));
    dispatch(findHolidaysNote());
  }, [skip]);

  //   map holidays data from API response
  const holidaysResponse = holidaysFindAllData && holidaysFindAllData?.data?.list;
  const holidaysData =
    holidaysResponse &&
    holidaysResponse.map((holiday) => {
      return { ...holiday };
    });

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const holidayId = data.row[0].value;
    if (holidayId && data?.action?.type === 'edit') {
      history.push(`/company/holidays/edit/${holidayId}`);
    }
  };

  // Table data
  const tableData = {
    tabs: tabs,
    module: 'company',
    count: holidaysFindAllData?.data?.count,
    skip: skip,
    data: holidaysData,
    head: HolidaysHead,
    formInputs: [],
    errorMessage: holidaysFindAllError?.message,
    hiddenKeys: ['actions'],
    inProgress: holidaysFindAllProcess,
  };

  // holiday note find data
  const holidayNote =
    !holidaysNoteFindProcess &&
    !holidaysNoteFindError &&
    holidaysNoteFindData &&
    holidaysNoteFindData?.data;
  const noteContent = {
    content: holidayNote,
    hiddenKeys: [],
    module: 'company',
  };
  return (
    <>
      <HolidaysNote {...noteContent} />
      <TabCustom {...tabs} />

      <Box mb={4}>
        <Holidays {...tableData} />
      </Box>
    </>
  );
};
