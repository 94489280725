import { Types } from './types';

const initState = {
  usersFindAllProcess: false,
  usersFindAllError: null,
  usersFindAllData: null,

  createUserProcess: false,
  createUserError: null,
  createUserData: null,

  userFindProcess: false,
  userFindError: null,
  userFindData: null,

  updateUserProcess: false,
  updateUserError: null,
  updateUserData: null,

  deleteUserProcess: false,
  deleteUserError: null,
  deleteUserData: null,
};

export function users(state = initState, action) {
  switch (action.type) {

    // Users find all
    case Types.START_USERS_FIND_ALL:
      return {
        ...state,
        usersFindAllProcess: true,
        usersFindAllError: null,
        usersFindAllData: null,
        userFindProcess: true,
        userFindError: null,
        userFindData: null,
      };
    case Types.END_USERS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        usersFindAllProcess: false,
        usersFindAllError: error,
        usersFindAllData: success,
      };
    }

    // Create user
    case Types.START_CREATE_USER:
      return {
        ...state,
        createUserProcess: true,
        createUserError: null,
        createUserData: null,
        userFindProcess: true,
        userFindError: null,
        userFindData: null,
      };
    case Types.END_CREATE_USER: {
      const { success, error } = action.payload;
      return {
        ...state,
        createUserProcess: false,
        createUserError: error,
        createUserData: success,
      };
    }

    // case: user find
    case Types.START_FIND_USER:
      return {
        ...state,
        userFindProcess: true,
        userFindError: null,
        userFindData: null,
      };
    case Types.END_FIND_USER: {
      const { success, error } = action.payload;
      return {
        ...state,
        userFindProcess: false,
        userFindError: error,
        userFindData: success,
      };
    }

    // case: update user
    case Types.START_UPDATE_USER:
      return {
        updateUserProcess: true,
        updateUserError: null,
        updateUserData: null,
        userFindProcess: true,
        userFindError: null,
        userFindData: null,
      };

    case Types.END_UPDATE_USER: {
      const { success, error } = action.payload;
      return {
        ...state,
        updateUserProcess: false,
        updateUserError: error,
        updateUserData: success,
      };
    }

    // delete user
    case Types.START_DELETE_USER:
      return {
        deleteUserProcess: true,
        deleteUserError: null,
        deleteUserData: null,
      };

    case Types.END_DELETE_USER: {
      const { success, error } = action.payload;
      return {
        ...state,
        deleteUserProcess: false,
        deleteUserError: error,
        deleteUserData: success,
      };
    }
    default:
      return state;
  }
}
